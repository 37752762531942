import { useDispatch, useSelector } from 'react-redux'
import useTemplateReducer from '../../../../reducers/template'
import useTimelineReducer from '../../../../reducers/timeline'
import moment from 'moment'


import validate from 'validate.js'

const constraints = {
    name: {
        presence: true
    },
    "studentUser.studentUserId": {
        presence: true
    },
    paymentStart: {
        presence: false
    },
    payments: {
        presence: true
    },
    pricingRate: {
        presence: true
    },
    discount: {
        presence: false
    },

    serviceId: {
        presence: false
    },
    from: {
        presence: true
    },
    to: {
        presence: true
    },

    taskTemplate: {
        presence: true
    },
    tasks: {
        presence: true
    },

    councelor: {
        presence: true
    },
    advisor: {
        presence: true
    },
    head: {
        presence: true
    },

    type: {
        presence: true
    },
    timeline: {
        presence: false
    },
    proposalVersion: {
        presence: true
    }
}

const useNewPlanModal = () => {
    const dispatch = useDispatch()
    //Para que hook funciones debe haber solo UN componente NewTaskModal 
    // renderizado en el dominio
    const reduxData = useSelector((state) => state.modal.counseling.newPlanModal)
    const {isOpen, onChange, isEdit, dataPlan} = reduxData
    const setOpen = (open, onChange = () => {}, isEdit = false, dataPlan = {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.newPlanModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                isEdit,
                dataPlan
            }
        })
    }
    //parsea timeline a [UserPhasesListInput!]
    const {parseTimelineToMutation} = useTemplateReducer()

    //parsea otro timeline a TimelineInput
    const {parseTimelineToMutation: parseTimelineToTimelineInput} = useTimelineReducer()

    return {
        isOpen,
        onChange,
        isEdit,
        dataPlan,
        open: (onChange = () => {}) => {
            setOpen(true, onChange)
        },
        setOpenToEdit: (onChange = () => {}, dataPlan = {}) => {
            setOpen(true, onChange, true, dataPlan)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        },
        parseDataToMutation: (data) => {
            //parse to type CouncelingPlanInput
            let paymentIndex = 1
            const councelingPlanInput =  {
                name: data.name,
                studentUser: {
                    studentUserId: data?.studentUser?.id || data?.studentUser?.studentUserId,
                    rate: data?.studentUser?.rate
                },
                //serviceId: data.serviceId,
                paymentItemsConfig: data?.payments?.payments?.filter(({amount}) => amount > 0).map(({amount, date, atSigning = false}) => {
                    //amount es el monto con el decuento ya aplicado
                    const percent = data?.discount || 0
                    return {
                        amount: parseFloat(amount),
                        percentDiscount: parseFloat(percent),
                        amountWithoutDiscount: parseFloat((parseFloat(amount)/(100-percent))*100),
                        date: date,
                        description: atSigning ? `At signing` : `Payment ${paymentIndex++}`,
                        type: atSigning ? 'otherFixed' : 'counselingPayment'
                    }
                }),
                statusPlan: 'draft',
                typePlanPricing: data?.typePlanPricing || 'permonth',
                pricingRate: parseFloat(data?.pricingRate),
                type: data?.type || 'undergraduate',
                taskTemplate: data?.taskTemplate,
                councelor: data?.councelor,
                advisor: data?.advisor,
                head: data?.head,
                tasks: parseTimelineToMutation(data?.tasks),
                from: moment(data?.from).toISOString(),
                to: moment(data?.to).toISOString(),
                studentGrade: data?.studentGrade,
                graduationDate: data?.graduationDate,
                proposalVersion: data?.proposalVersion
            }
            //parse type TimelineInput
            const timelineInput = data?.timeline ? parseTimelineToTimelineInput(data.timeline, data?.studentUser) : null
            return {
                councelingPlanInput,
                timelineInput
            }
        },
        validation: (form) => {
            const valParams = constraints
            // studentGrade
            if(form.type === 'undergraduate') {
                valParams.graduationDate = {presence: true}
                valParams.studentGrade = {presence: true}
            } 
            return validate(form, {...valParams})
        }
    }
}

export default useNewPlanModal