import { useDispatch, useSelector } from "react-redux"

const useDismissNotificationModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.dashboard.issueDismissNotification)
    const {isOpen} = reduxData
    const setData = (isOpen = false, onChange = () => {}, student = {}, reason, serviceReason) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'dashboard.issueDismissNotification',
            data: {
                ...reduxData,
                isOpen,
                onChange,
                student,
                reason,
                serviceReason
            }
        })
    }

    const closeDelay = () => {
        setData(false, reduxData?.onChange, reduxData?.student, reduxData?.reason, reduxData?.serviceReason)
        setTimeout(() => {
            setData(false)
        }, 5000)
    }
    return {
        ...reduxData,
        open: (onChange = () => {}, student = {}, reason = 'homework', serviceReason = null) => {
            setData(true, onChange, student, reason, serviceReason)
        },
        close: closeDelay,
        toggle: () => {
            if(isOpen) closeDelay()
            else setData(!isOpen)
        },
    }
}

export default useDismissNotificationModal