import { useQuery, gql } from '@apollo/client'
import queryLessonResult from '../../Calendar/Model/useQueryCalendarView/queryLessonResult'

const useLessonsQuery = (variables = {}, options = {fetchPolicy: 'cache-first'}) => {
    return useQuery(
        gql`
            query (
                $studentIds: [String!]
                $sortField: String
                $previous: String
                $next: String
                $limit: Int
                $serviceIds: [ID!]
                $endDate: DateTime
                $startDate: DateTime
                $planId: String
                $status: [LessonStatus!]!
                $filterStatus: [String!]
                $educatorIds: [String!]
                $search: String
                $typeLessons: [String!]
            ) {
                lessons (
                    studentIds: $studentIds
                    sortField: $sortField
                    previous: $previous
                    next: $next
                    limit: $limit
                    serviceIds: $serviceIds
                    endDate: $endDate
                    startDate: $startDate
                    planId: $planId
                    status: $status
                    filterStatus: $filterStatus
                    educatorIds: $educatorIds
                    search: $search
                    typeLessons: $typeLessons
                ) {
                    hasNext
                    hasPrevious
                    next
                    previous
                    totalDocs
                    docs {
                        ${queryLessonResult}
                    }
                }
            }
        `,
        {
            variables,
            ...options
        }
    )
}

export default useLessonsQuery