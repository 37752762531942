import React, { useEffect, useState } from "react";
import {
  IconRight,
  ListItem,
  IconLink,
} from "@Knowledge-OTP/znk-ui-components";
import { useDispatch, useSelector } from "react-redux";
import { SET_DATA_MODAL_FORM_LIST } from "../../../../../store/modal/const";
import formatNumber from "../../../../../utils/formatNumber";
import { gql, useLazyQuery } from "@apollo/client";
import EditGeneralInfo from "../../../Components/editGeneralInfo";
import EditBankInfo from "../../../Components/editBankInfo";
import useTranslation from "../../../../../i18n/useTranslation";
import CourseMaterialsStudent from "../../../../Student/modal/InfoStudent/Profile/CourseMaterials";
import * as moment from "moment";
import useCopyText from "../../../../../common/CopyText/useCopyText";
import { showAlert } from "../../../../../common/Alert/util";
import ViewTeachTopics from "../../Educator/ViewTechTopics";
import DisabledForStudent from "./DisabledForStudent";
import EducatorCalendarLink from "../../Profile/Components/EducatorCalendarLink";
import GoogleDriveFolder from "../../Profile/Components/GoogleDriveFolder";

const SetStudentRateContent = () => {
  const { translate } = useTranslation();
  const { isOpen, typeUser, ...formData } = useSelector(
    (state) => state.modal.user.servicesRate
  );
  const [copyText, setCopyText] = useCopyText();
  const [isOpenSend, setIsOpenSend] = useState(false);
  const copyFn = (link) => {
    setCopyText(link, copySuccess, copyError);
  };

  const [copySuccess, copyError] = [
    (text) => {
      //success
      showAlert(
        {
          text: translate("profile.actions.employmentdetails.copySuccess"),
          status: "success",
        },
        dispatch
      );
    },
    (error, text) => {
      //error
      showAlert(
        {
          text: `${translate(
            "profile.actions.employmentdetails.copyError"
          )} \n ${copyText}`,
          status: "error",
        },
        dispatch
      );
    },
  ];

  const [formGlobal, setFormGlobal] = useState({
    customAmount: 0,
    globalAmount: 0,
    servicesRate: [],
    type: typeUser,
    servicesRateEducator: [],
  });

  const [getUser, { data, refetch }] = useLazyQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          id
          createdAt
          emails {
            address
            verified
          }
          staffInfo {
            backupLink
          }
          countryId
          country {
            id
            name
            defaultStudentRate
            defaultEducatorRate
            servicesRate {
              serviceId
              name
              defaultRate
            }
          }
          gender
          spokenLanguages
          address
          city
          state
          zipCode
          firstName
          lastName
          phone
          timezone
          birthdate
          studentInfo {
            institution
            graduationDate
            gpa
            defaultRate
            aditionalInfo
            materials
            notifications {
              missedLesson
              lessonsNotesReady
              lessonReminder {
                isAvailable
                duration
              }
            }
            servicesRate {
              defaultRate
              name
              serviceId
            }
            parents {
              parentUserId
              managePayments
              manageApprovals
              firstName
              lastName
              email
            }
            folderDriveId
          }
          educatorInfo {
            disableForStudents
            defaultRate
            services {
              serviceId
              name
              defaultRate
              topics {
                topicId
                teachingTypes
                name
                individualRate
                groupRate
                isWaiveIndividual
                isWaiveGroup
              }
            }
            canTeach
            backupLink
            weeklyCapHours
            isActive
            bankNumber
            bankRouting
            googleCalendar
            handbook
            sinceDate
            availabilityBlocks {
              day
              hour
            }
            rating
          }
          isActive
          roles
        }
      }
    `,
    {
      variables: { id: formData.userId },
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    if (formData.userId && formData.userId !== "") {
      getUser();
    }
    // eslint-disable-next-line
  }, [formData && formData.userId]);
  useEffect(() => {
    if (data) {
      setFormGlobal({
        labelStudent: data?.user?.studentInfo?.defaultRate
          ? data.user.studentInfo.defaultRate !==
            data.user.country.defaultStudentRate
            ? "Custom"
            : data.user.country.name
          : data.user.country.name,
        customAmountStudent: data?.user?.studentInfo?.defaultRate || 0,
        globalAmountStudent: data.user.country
          ? data.user.country.defaultStudentRate
          : 0,
        type: typeUser,
        servicesRate: data?.user?.studentInfo?.servicesRate || 0,
        country: data?.user?.country,
        servicesRateEducator: data?.user?.educatorInfo?.services,
        customAmountEducator: data?.user?.educatorInfo?.defaultRate || 0,
        globalAmountEducator: data?.user?.country?.defaultEducatorRate
          ? data.user.country?.defaultEducatorRate
          : 0,
        labelEducator:
          data?.user?.educatorInfo?.defaultRate &&
          data?.user?.country?.defaultEducatorRate
            ? data.user.educatorInfo.defaultRate !==
              data.user.country.defaultEducatorRate
              ? "Custom"
              : data?.user?.country?.name
            : "Custom",
        backupLink:
          typeUser === "staff"
            ? data?.user?.staffInfo?.backupLink || ""
            : data?.user?.educatorInfo?.backupLink || "",
        weeklyCapHours: data?.user?.educatorInfo?.weeklyCapHours,
        materials: data?.user?.studentInfo?.materials || "",
        bankNumber: data?.user?.educatorInfo?.bankNumber,
        bankRouting: data?.user?.educatorInfo?.bankRouting,
        googleCalendar: data?.user?.educatorInfo?.googleCalendar,
        googleDriveFolder: data?.user?.studentInfo?.folderDriveId,
        handbook: data?.user?.educatorInfo?.handbook,
        sinceDate: data?.user?.educatorInfo?.sinceDate
          ? data?.user?.educatorInfo?.sinceDate
          : moment(data?.user?.createdAt).format("YYYY"),
        canTeach: data?.user?.educatorInfo?.canTeach
          ? data?.user?.educatorInfo?.canTeach
          : "",
        disableForStudents:
          data?.user?.educatorInfo?.disableForStudents || false,
      });
    }
  }, [data, typeUser]);

  const dispatch = useDispatch();
  // const closeOver = () => {
  //     dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRate', data: { isOpen: false } })
  //     dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRateShort', data: { isOpen: false } })
  // }
  const sendOpen = () => {
    setIsOpenSend(true);
  };
  const editGeneral = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: "user.editGeneralInfo",
      data: {
        isOpen: true,
        type: typeUser,
        userId: data.user.id,
        materials: data.user.studentInfo ? data.user.studentInfo.materials : "",
        backupLink: data.user.educatorInfo
          ? data.user.educatorInfo.backupLink
          : "",
        weeklyCapHours: data.user.educatorInfo
          ? data.user.educatorInfo.weeklyCapHours
          : 0,
        googleCalendar: data.user.educatorInfo.googleCalendar
          ? data.user.educatorInfo.googleCalendar
          : "",
        handbook: data.user.educatorInfo.handbook
          ? data.user.educatorInfo.handbook
          : "",
        sinceDate: data?.user?.educatorInfo?.sinceDate
          ? data?.user?.educatorInfo?.sinceDate
          : data?.user?.createdAt
          ? data.user.createdAt
          : "",
      },
    });
  };
  const editGlobalRate = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: "user.globalRate",
      data: {
        isOpen: true,
        typeUser: typeUser,
        userId: formData.userId,
        refetch: refetch,
      },
    });
  };
  const EditCustomServiceEducator = (item, element) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: "user.customService",
      data: {
        isOpen: true,
        typeUser: typeUser,
        userId: formData.userId,
        form: formGlobal,
        item: item,
        element: element,
        setForm: setFormGlobal,
      },
    });
  };
  const EditCustomService = (item) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: "user.customService",
      data: {
        isOpen: true,
        typeUser: typeUser,
        userId: formData.userId,
        form: formGlobal,
        item: item,
        setForm: setFormGlobal,
      },
    });
  };
  const addServices = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: "user.addCustomService",
      data: {
        isOpen: true,
        typeUser: typeUser,
        userId: formData.userId,
        form: formGlobal,
        setForm: setFormGlobal,
      },
    });
  };
  const editPayment = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: "user.editBankInfo",
      data: {
        isOpen: true,
        type: typeUser,
        userId: data.user.id,
        bankNumber: data.user.educatorInfo
          ? data.user.educatorInfo.bankNumber
          : undefined,
        bankRouting: data.user.educatorInfo
          ? data.user.educatorInfo.bankRouting
          : undefined,
      },
    });
  };
  const changeToPassView = (value) => {
    if (value) {
      const len = String(value).length;
      return "".padStart(len - 4, "*") + String(value).substr(len - 4, len);
    }
    return "";
  };

  return (
    <>
      <EditGeneralInfo refetch={getUser} />
      <EditBankInfo refetch={getUser} />
      {typeUser === "educator" && (
        <>
          <div className="mt-6 d-flex flex-row justify-content-between">
            <span className="font-weight-bold">Payment</span>
            <span className="btn-link cursor" onClick={editPayment}>
              {typeUser === "student" ? "" : "Edit"}
            </span>
          </div>
          <div className="borderTop mt-3" />
          <div className="d-flex flex-row justify-content-between mt-3">
            <span className="text-title">
              {translate("profile.actions.editBankInfo.banknumber")}
            </span>
            <div>
              <span className="text-gray mr-2">
                {formGlobal.bankNumber
                  ? changeToPassView(formGlobal.bankNumber)
                  : "-"}
              </span>
            </div>
          </div>
          <div className="borderTop mt-3" />
          <div className="d-flex flex-row justify-content-between mt-3">
            <span className="text-title">
              {translate("profile.actions.editBankInfo.bankrouting")}
            </span>
            <div>
              <span className="text-gray mr-2">
                {formGlobal.bankRouting
                  ? changeToPassView(formGlobal.bankRouting)
                  : "-"}
              </span>
            </div>
          </div>
          <div className="borderTop mt-3" />
        </>
      )}
      {typeUser !== "staff" && (
        <>
          <div className="d-flex text-dark justify-content-between mb-1 mt-6">
            <span className="font-weight-bold">
              {translate("profile.actions.editservices.services")}
            </span>
            {typeUser === "educator" && (
              <button className="btn btn-link p-0" onClick={sendOpen}>
                {translate("profile.actions.editGeneralInfo.viewservices")}
              </button>
            )}
          </div>
          <div className="borderTop mt-1" />
          {typeUser === "educator" && (
            <>
              <DisabledForStudent
                refetch={refetch}
                educatorId={formData.userId}
                disableForStudents={
                  data?.user?.educatorInfo?.disableForStudents
                }
              />
              {/* <div className='d-flex flex-row justify-content-between mt-3'>
                                    <span className='text-title'>Abled for student</span>
                                    <div>
                                        <Switch
                                            initialState={true} onChange={() => {}} 
                                            getState={() => {}} style={{}}
                                        />
                                    </div>
                                </div> */}
              <div className="borderTop mt-3" />
              <div className="d-flex flex-row justify-content-between mt-3">
                <span className="text-title">
                  {translate("profile.actions.editGeneralInfo.maxhours")}
                </span>
                <div>
                  <span className="text-gray mr-2">
                    {formGlobal?.weeklyCapHours}
                  </span>
                  <IconRight size={16} color="graytext" />
                </div>
              </div>
              <div className="borderTop mt-3" />
            </>
          )}
        </>
      )}
      {typeUser === "student" &&
        (formGlobal?.servicesRate || [])?.map((item) => {
          return (
            <>
              <div
                className="d-flex flex-row justify-content-between mt-3 cursor"
                onClick={() => {
                  EditCustomService(item);
                }}
              >
                <span className="text-title">{item.name}</span>
                <div className="d-flex flex-row align-items-center">
                  <span className="text-gray mr-2">
                    USD ${formatNumber(item.defaultRate)}
                  </span>
                  <IconRight size={16} color="graytext" />
                </div>
              </div>
              <div className="borderTop mt-3" />
            </>
          );
        })}
      {typeUser === "educator" &&
        (formGlobal?.servicesRateEducator || [])?.map((item) => {
          return item.topics
            ? item.topics.map((element) => {
                if (element?.teachingTypes?.length > 0) {
                  return (
                    <>
                      <div
                        className="d-flex flex-row justify-content-between mt-3 cursor"
                        onClick={() => {
                          EditCustomServiceEducator(item, element);
                        }}
                      >
                        <span className="text-title">
                          {item.name} - {element.name}
                        </span>
                        <div className="d-flex flex-row align-items-center">
                          <span className="text-gray mr-2">
                            {!element.teachingTypes.includes("individual")
                              ? "-"
                              : element.isWaiveIndividual
                              ? "Waive"
                              : "USD $" +
                                formatNumber(element.individualRate)}{" "}
                            /{" "}
                            {!element.teachingTypes.includes("group")
                              ? "-"
                              : element.isWaiveGroup
                              ? "Waive"
                              : "USD $" + formatNumber(element.groupRate)}
                          </span>
                          <IconRight size={16} color="graytext" />
                        </div>
                      </div>
                      <div className="borderTop mt-3" />
                    </>
                  );
                }
                return null;
              })
            : null;
        })}
      {typeUser !== "staff" && (
        <div className="d-flex flex-row mt-4">
          <span className="btn-link cursor" onClick={addServices}>
            {translate("profile.actions.editservices.add")}
          </span>
        </div>
      )}
      <div className="mt-6 d-flex flex-row justify-content-between">
        <span className="font-weight-bold">General information</span>
        <span className="btn-link cursor" onClick={editGeneral}>
          {typeUser === "student" ? "" : "Edit"}
        </span>
      </div>
      <div className="borderTop mt-1" />
      {typeUser === "student" ? (
        <>
          {/**<div className='d-flex flex-row justify-content-between mt-3 cursor' onClick={editGeneral}>
                         <span className='text-title'>{translate('profile.actions.editGeneralInfo.coursematerials')}</span>
                        <div>
                            <span className='text-gray mr-2'>{formGlobal?.materials}</span>
                            <IconRight size={16} color='graytext' />
                        </div>
                        </div>**/}
          <EducatorCalendarLink
            user={data?.user || {}}
            refetch={refetch}
            role={"student"}
          />
          <GoogleDriveFolder user={data?.user || {}} />
          <ListItem
            showBottomLine
            onClick={editGeneral}
            className="py-4 w-100"
            styleLeft={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            Left={
              <div className="d-flex flex-row">
                <span
                  className="align-self-center text-title"
                  style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  title={translate(
                    "profile.actions.editGeneralInfo.coursematerials"
                  )}
                >
                  {translate("profile.actions.editGeneralInfo.coursematerials")}
                </span>
              </div>
            }
            Right={
              <div
                className={`m-0 p-0 d-flex flex-row align-items-center overflow-hidden`}
              >
                <span
                  className="text-truncate text-gray mr-2"
                  style={{ maxWidth: "210px" }}
                >
                  {formGlobal?.materials}
                </span>
                <IconRight size={16} color="graytext" />
              </div>
            }
          />

          <div className="d-flex flex-row justify-content-between mt-3 cursor">
            <span className="text-title font-weight-bold">
              {translate("profile.actions.editservices.materialsTopic.title")}
            </span>
          </div>

          <CourseMaterialsStudent userId={formData.userId} />
        </>
      ) : typeUser === "staff" ? (
        <>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <span className="text-title">
              {translate("profile.actions.editGeneralInfo.backuplink")}
            </span>
            <div
              className={`w-70 d-flex flex-row justify-content-end align-items-center ${
                formGlobal?.backupLink ? "mr-1" : ""
              } `}
            >
              <div
                className={`w-50 ${
                  formGlobal?.backupLink?.length > 20
                    ? "text-truncate"
                    : "d-flex flex-row justify-content-end"
                }`}
                title={formGlobal?.backupLink}
              >
                {formGlobal?.backupLink ? (
                  <span className="text-gray mr-2">
                    {formGlobal?.backupLink}{" "}
                  </span>
                ) : (
                  <span className="text-gray mr-2">-</span>
                )}
              </div>{" "}
              {formGlobal?.backupLink && (
                <div className={"hover-icon"}>
                  <IconLink
                    size={16}
                    color={"gray"}
                    onClick={() => {
                      copyFn(formGlobal?.backupLink);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex flex-row justify-content-between mt-3">
            <span className="text-title">Since</span>
            <div>
              <span className="text-gray mr-2">
                {formGlobal?.sinceDate || "-"}
              </span>
            </div>
          </div>
          <div className="borderTop mt-3" />
          <EducatorCalendarLink user={data?.user || {}} refetch={refetch} />
          {/* <div className='d-flex align-items-center justify-content-between mt-3'>
                            <span className='text-title'>{translate('profile.actions.editGeneralInfo.googlefeed')}</span>
                            <div className={`w-70 d-flex flex-row justify-content-end align-items-center ${formGlobal?.googleCalendar ? 'mr-1' : ''} `}>
                            <div className={`w-50 ${formGlobal?.googleCalendar?.length > 20 ? 'text-truncate' : 'd-flex flex-row justify-content-end'}`} title={formGlobal?.googleCalendar}>
                            {formGlobal?.googleCalendar ? <span className='text-gray mr-2'>{formGlobal?.googleCalendar} </span>
                            : <span className='text-gray mr-2'>-</span> }
                            </div> {formGlobal?.googleCalendar && <div className={'hover-icon'}>
                                <IconLink size={16} color={'gray'} onClick={()=>{copyFn(formGlobal?.googleCalendar)}}/>
                                </div>}
                            </div>
                        </div> */}
          <div className="borderTop mt-3" />
          <div className="d-flex align-items-center justify-content-between mt-3">
            <span className="text-title">
              {translate("profile.actions.editGeneralInfo.backuplink")}
            </span>
            <div
              className={`w-70 d-flex flex-row justify-content-end align-items-center ${
                formGlobal?.backupLink ? "mr-1" : ""
              } `}
            >
              <div
                className={`w-50 ${
                  formGlobal?.backupLink?.length > 20
                    ? "text-truncate"
                    : "d-flex flex-row justify-content-end"
                }`}
                title={formGlobal?.backupLink}
              >
                {formGlobal?.backupLink ? (
                  <span className="text-gray mr-2">
                    {formGlobal?.backupLink}{" "}
                  </span>
                ) : (
                  <span className="text-gray mr-2">-</span>
                )}
              </div>{" "}
              {formGlobal?.backupLink && (
                <div className={"hover-icon"}>
                  <IconLink
                    size={16}
                    color={"gray"}
                    onClick={() => {
                      copyFn(formGlobal?.backupLink);
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="borderTop mt-3" />
          <div className="d-flex flex-row justify-content-between mt-3">
            <span className="text-title">
              {translate("profile.actions.editGeneralInfo.w9")}
            </span>
            <div>
              <span className="text-gray mr-2">
                {formGlobal?.wFileUpload || "-"}
              </span>
            </div>
          </div>
          <div className="borderTop mt-3" />
          <div className="d-flex flex-row justify-content-between mt-3">
            <span className="text-title">
              {translate("profile.actions.editGeneralInfo.nda")}
            </span>
            <div>
              <span className="text-gray mr-2">
                {formGlobal?.ndaFileUpload || "-"}
              </span>
            </div>
          </div>
          <div className="borderTop mt-3" />
          <div className="d-flex align-items-center justify-content-between mt-3">
            <span className="text-title">
              {translate("profile.actions.editGeneralInfo.handbook")}
            </span>
            <div
              className={`w-70 d-flex flex-row justify-content-end align-items-center ${
                formGlobal?.handbook ? "mr-1" : ""
              } `}
            >
              <div
                className={`w-50 ${
                  formGlobal?.handbook?.length > 20
                    ? "text-truncate"
                    : "d-flex flex-row justify-content-end"
                }`}
                title={formGlobal?.handbook}
              >
                {formGlobal?.handbook ? (
                  <span className="text-gray mr-2">
                    {formGlobal?.handbook}{" "}
                  </span>
                ) : (
                  <span className="text-gray mr-2">-</span>
                )}
              </div>{" "}
              {formGlobal?.handbook && (
                <div className={"hover-icon"}>
                  <IconLink
                    size={16}
                    color={"gray"}
                    onClick={() => {
                      copyFn(formGlobal?.handbook);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="borderTop mt-3" />
          <div className="d-flex flex-row justify-content-between mt-3">
            <span className="text-title">
              {translate("profile.actions.editGeneralInfo.canTeach")}
            </span>
            <div>
              <span className="text-gray mr-2">
                {formGlobal?.canTeach || "-"}
              </span>
            </div>
          </div>
        </>
      )}
      {typeUser === "student" && (
        <>
          <div className="borderTop mt-3" />
          <div
            className="d-flex flex-row justify-content-between mt-3 cursor"
            onClick={editGlobalRate}
          >
            <span className="text-title">
              {translate("profile.actions.editservices.global")}
            </span>
            <div className="d-flex flex-row align-items-center">
              {typeUser === "student" ? (
                <span className="text-gray mr-2">
                  ({formGlobal?.labelStudent}) USD ${" "}
                  {formatNumber(
                    formGlobal?.labelStudent === "Custom"
                      ? formGlobal?.customAmountStudent
                      : formGlobal?.globalAmountStudent
                  )}
                </span>
              ) : (
                <span className="text-gray mr-2">
                  ({formGlobal?.labelEducator}) USD ${" "}
                  {formatNumber(
                    formGlobal?.labelEducator === "Custom"
                      ? formGlobal?.customAmountEducator
                      : formGlobal?.globalAmountEducator
                  )}
                </span>
              )}
              <IconRight size={16} color="graytext" />
            </div>
          </div>
        </>
      )}
      <ViewTeachTopics
        isOpen={isOpenSend}
        setIsOpen={setIsOpenSend}
        canTeach={formGlobal?.canTeach || ""}
      />
    </>
  );
};

export default SetStudentRateContent;
