import React from 'react';
import {
  CheckboxSelector,
  IconArrowDown,
} from '@Knowledge-OTP/znk-ui-components';
import useTranslation from '../../../../i18n/useTranslation';
import { Badge } from 'reactstrap'

const TypeLessonFilter = (props) => {
  const {
    setFilters = () => {},
    filters = [],
  } = props;
  const { translate } = useTranslation();

  const checkboxSelectorProps = {
    setDataFilters: setFilters,
    dataFilters: filters,
    direction: 'bottomLeft',
    data: [{ name: 'Individual', id: 'individual-lesson' }, { name: 'Group', id: 'group-lesson' }],
    typeFilter: 'typeLessons',
    strings: {
      viewAll: translate('filters.plans.viewAll'),
      buttonCancel: translate('filters.plans.buttonCancel'),
      buttonApply: translate('filters.plans.buttonApply'),
    }
  }

  return (
    <CheckboxSelector {...checkboxSelectorProps}>
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          Type
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  );
};

export default TypeLessonFilter;
