import React from 'react';
import { InputSelect } from '@Knowledge-OTP/znk-ui-components';

const TypeLessonSelector = (props) => {
  const { filters = null, setFilters = () => {} } = props;
  return (
    <div className={`d-flex flex-column`}>
      <div className={`w-100 d-flex flex-row justify-content-start`}>
        <span className='h45 text-gray'>Type</span>
      </div>
      <InputSelect
        className={`my-3`}
        options={[
          { value: 'individual-lesson', label: 'Individual' },
          { value: 'group-lesson', label: 'Group' },
        ]}
        onChange={setFilters}
        value={filters}
        menuPlacement={'top'}
        name={`typeLessonSelector`}
      />
    </div>
  );
};

export default TypeLessonSelector;
