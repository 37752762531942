import React, {useMemo} from 'react'
import { ConfirmModal } from '@Knowledge-OTP/znk-ui-components'
import useDismissNotification from '../DismissNotification/useDismissNotification'
import useIssueCheckInToStudent from '../../Model/useIssueCheckInToStudent'
import { showAlert } from '../../../../../../../common/Alert/util'
import useTranslation from '../../../../../../../i18n/useTranslation'
import { useDispatch } from 'react-redux'

const DismissNotificationModal = () => {
    const {isOpen, toggle, onChange, student, reason, serviceReason} = useDismissNotification()
    const studentId = useMemo(() => student?.id || student?._id, [student])
    const checkInId = useMemo(() => student?.checkInDate?.id || null, [student])
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    const [dismissNotification, {loading}] = useIssueCheckInToStudent(
        (res) => {
            if(onChange) onChange()
            showAlert({
                text: 'Success!',
                status: 'success'
            }, dispatch)
        },
        (err) => {
            showAlert({
                text: 'Error!',
                status: 'error'
            }, dispatch)
        }
    )
    return (
        <ConfirmModal
            className=''
            classNameActionDiv=''
            classNameConfirm='text-error'
            classNameCancel=''
            isOpen={isOpen}
            toggle={toggle}
            title={translate("dashboard.issueCheckIn.listItem.dismissModal.sure") || 'Are you sure?'}
            body={translate("dashboard.issueCheckIn.listItem.dismissModal.body") || 'This action cannot be undoned'}
            confirmAction={() => {
                const variables = {
                    studentId,
                    reason,
                    checkInId,
                    serviceReason
                }
                console.log('variables', studentId, reason, checkInId, serviceReason)
                dismissNotification({variables})
            }}
            cancelAction={toggle}
            confirmText={translate("dashboard.issueCheckIn.listItem.dismissModal.confirm") || 'Confirm'}
            cancelText={translate("dashboard.issueCheckIn.listItem.dismissModal.cancel") || 'Cancel'}
            loading={loading}
        />
    )
}

export default DismissNotificationModal