import React, { useEffect, useState, Fragment, useMemo } from 'react'
import {
  Modal,
  IconClose,
  InvoiceCard,
  ListItem,
  IconRight,
  IconAlert,
  IconLesson,
  IconEdit,
  IconEmail,
  IconDolarRefresh,
  IconCircleDolar,
  IconSignPlus,
  StickyButton,
  Button,
  IconOptions,
  DropDown,
  IconErase,
  ConfirmModal,
  IconLink
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import moment from 'moment'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import './index.css'
import PlanStudent from '../../../Plans/modal/newPlan/Components/PlanStudent'
import formatNumber from '../../../../utils/formatNumber'
import getPaymentsType from '../../../../utils/getPaymentsType'
import cancelPaymentItemFromAdmin from './cancelPaymentItemFromAdmin'
import voidInvoiceFromAdmin from './voidInvoice'
import cancelInvoiceFromAdmin from './cancelInvoice'
import EditPaymentItem from '../../Payments/edit/editPayemntItem'
import EditCustomPaymentItem from '../../Payments/edit/editCustomPaymentItem'
import EditLessonPaymentItem from '../../Payments/edit/editLessonPaymentItem'
import NewRecord from '../record'
import MakeRefund from '../makeRefund'
import Skeleton from 'react-loading-skeleton'
import approveInvoice from '../../../../Model/Payments/Invoices/Approve'
import processInvoice from '../../../../Model/Payments/Invoices/ProccessOne'
import {showAlert} from '../.././../../common/Alert/util'
import ReceiptComponent from '../components/stripeReceipt'
import useViewInvoiceWithPymtItem from '../viewInvoiceWithPaymentItem/useViewInvoiceWithPymtItem'
import ChangePaymentMethod from '../components/ChangePaymentMethod'
import useGetInvoiceLink from '../../../../Model/Payments/GetInvoiceLink'

const ViewInvoice = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.viewInvoice)
  const { isOpen, invoiceId } = modalData
  const {setPaymentId} = useViewInvoiceWithPymtItem()
  const [isEdit] = useState(false)
  const [state, setState] = useState({ id: '', state: '' })
  const [confirm, setConfirm] = useState(false)
  const [update] = useState(false)
  const [isExit, setIsExit] = useState(false)
  const [refunded, setRefunded] = useState('none')
  const [paidSoFar, setPaidSoFar] = useState(0)
  const [paidManual, setPaidManual] = useState(0)
  const [issues, setIssues] = useState([])
  const [stripeIds, setStripeIds] = useState([])
  const [method, setMethod] = useState('')
  const [paidOn, setPaidOn] = useState('')
  const [form, setForm] = useState({
    student: undefined,
    items: []
  })
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [meInvoice, { data }] = useLazyQuery(
    gql`query($invoiceId: String!){
      invoice(invoiceId: $invoiceId) {
        id
        invoiceNumber
        userId {
          id
          firstName
          lastName
          avatar
          paymentMethod {
            kind
            ... on CardPaymentMethod {
              brand
              last4
              id
            }
            ... on BankDebitPaymentMethod {
              accountInfo {
                bankName
                last4
              }
            }
            ... on ZellePaymentMethod {
              zellePaymentInfo {
                emailToSend
              }
            }
          }
        }
        billedUserId
        processedTimes
        createdAt
        updatedAt
        category
        from
        to
        status
        createdInfo {
          firstName
          lastName
          succeded
        }
        updatedInfo {
          firstName
          lastName
          succeded
        }
        paymentItems {
          ... on PlanPaymentItem {
            generatedDescription
            id
            planId
            kind
            createdAt
            updatedAt
            category
            amount
            percentDiscount
            amountWithoutDiscount
            percentUnpaid
            date
            draft
            status
            lastProcessedAt
            description
            type
          }
          ... on LessonPaymentItem {
            generatedDescription
            lessonId
            id
            kind
            createdAt
            updatedAt
            category
            amount
            percentDiscount
            amountWithoutDiscount
            percentUnpaid
            date
            draft
            status
            lastProcessedAt
            description
            type
          }
          ... on EducatorPaymentItem {
            id
            kind
            createdAt
            updatedAt
            category
            amount
            percentDiscount
            amountWithoutDiscount
            percentUnpaid
            date
            draft
            status
            lastProcessedAt
            description
            type
          }
          ... on CustomPaymentItem {
            id
            kind
            createdAt
            updatedAt
            category
            amount
            percentDiscount
            amountWithoutDiscount
            percentUnpaid
            date
            draft
            status
            lastProcessedAt
            description
            type
          }
        }
        paymentItemIds
        lastProcessedAt
        amount
        taxFee
        amounts {
          subTotal
          taxAmount
          amount
        }
        issuedInvoices {
          stripeInvoiceId
          status
          paymentMethod
          amount
          lastProcessedAt
          issuedCreditNotes {
            creditNoteId
            amount
            stripeInfo {
              created
              currency
              discount_amount
              number
              out_of_band_amount
              pdf
              subtotal
              total
            }
          }
        }
        manualPayments {
          stripeInvoiceId
          amount
          paymentMethod
          lastProcessedAt
          status
        }
      }
    }`,
    {
      variables: { invoiceId: invoiceId },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    (async () => {
      if (!invoiceId) return
      await meInvoice()
    })()
  }, [invoiceId, meInvoice, update])
  useEffect(() => {
    if (data) {
      setForm({
        student: data.invoice?.userId,
        items: !data && !data.invoice ? [] : data.invoice?.paymentItems.map(
          e => {
            return {
              ...e,
              hover: false
            }
          }
        )
      })
      const amountRefund = data.invoice?.issuedInvoices.length === 0 ? 0 : data.invoice?.issuedInvoices.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.issuedCreditNotes.reduce((accu, curr) => { return accu + curr.amount }, 0)
      }, 0)
      setStripeIds(data.invoice?.manualPayments.map(manual => { return manual.stripeInvoiceId }))
      let amountPaid = data.invoice?.paymentItems.reduce((accumulator, currentValue) => { return accumulator + (currentValue.percentUnpaid === 0 ? currentValue.amount : parseFloat((currentValue.amount - currentValue.amount * (currentValue.percentUnpaid / 100)).toFixed(2))) }, 0)
      // if (data.invoice?.taxFee > 0) {
      //   amountPaid = (amountPaid * (1 + (data.invoice?.taxFee / 100))).toFixed(2)
      // }
      // This in not neccesary 'Cause,
      // if (amountPaid < data.invoice?.amount) {
      //   amountPaid += data.invoice?.manualPayments?.reduce((acum, item) => { return acum + item.amount}, 0)
      // }
      setRefunded(amountRefund === 0 ? 'none' : amountRefund < data.invoice?.amounts.amount ? 'partial' : 'full')
      //const manualPay = data.invoice ? data.invoice.manualPayments.reduce((acum, item) => {return acum + item.amount}, 0) : 0
      //setPaidSoFar(amountPaid < data.invoice.amount ? amountPaid + manualPay : amountPaid)
      // if (data.invoice?.amount !== amountPaid) {
      //   setPaidSoFar(data.invoice?.amount - amountPaid)
      // } else {
      setPaidSoFar(amountPaid)
      setPaidManual(data.invoice?.amount - amountPaid)
      // }
      if (data.invoice?.issuedInvoices.length > 0) {
        setIssues(data.invoice?.issuedInvoices)
      }
      if (data.invoice?.status === 'paid') {
        if (data.invoice.issuedInvoices?.length) {
          const issue = data.invoice.issuedInvoices.find(e => e.status === 'paid')
          if (issue) {
            setPaidOn(issue.lastProcessedAt)
          } else {
            if (data.invoice.manualPayments.length) {
              const manual = data.invoice.manualPayments.filter(e => e.status === 'paid')
              if (manual.length) {
                setPaidOn(manual[manual.length - 1].lastProcessedAt)
              } else {
                setPaidOn(data.invoice.lastProcessedAt)
              }
            } else {
              setPaidOn(data.invoice.lastProcessedAt)
            }
          }
        } else {
          if (data.invoice.manualPayments.length) {
            const manual = data.invoice.manualPayments.filter(e => e.status === 'paid')
            console.log({ manual, ver: data.invoice.manualPayments })
            if (manual.length) {
              setPaidOn(manual[manual.length - 1].lastProcessedAt)
            } else {
              setPaidOn(data.invoice.lastProcessedAt)
            }
          } else {
            setPaidOn(data.invoice.lastProcessedAt)
          }
        }
      }
    } else {
      if (data === undefined && state.state === 'remove') {
        toggle()
        return
      }
      if (isExit) {
        toggle()
      }
    }
    // eslint-disable-next-line
  }, [data])
  const toggle = () => {
    setPaymentId('')
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.viewInvoice',
      data: {
        isOpen: false,
        invoiceId: ''
      }
    })
  }
  const getStatus = (invoice, witch) => {
    let modalState
    switch (invoice?.status) {
      case 'draft':
        modalState = 'draft'
        break
      case 'pendingPayment':
        if (invoice.category === 'livePrep') {
          modalState = 'live'
        } else {
          modalState = 'counceling'
        }
        break
      case 'procesingPayment':
        modalState = 'live'
        break
      case 'unPaid':
        modalState = 'pendingPayment'
        break
      case 'paid':
        modalState = 'paid'
        break
      case 'canceled':
        modalState = 'canceled'
        break
      default:
        modalState = 'scheduled'
        break
    }
    if (witch === 'modal') {
      switch (modalState) {
        case 'draft':
          return 'scheduled'
        case 'live':
          return 'upcoming'
        case 'counceling':
          return 'counceling'
        case 'pendingPayment':
          return 'error'
        case 'paid':
          return 'paid'
        case 'canceled':
          return 'canceled'
        default:
          return ''
      }
    } else {
      return modalState
    }
  }
  const getTop = (status) => {
    switch (status) {
      case 'canceled':
        return '- CANCELED'
      case 'paid':
        return '- PAID'
      case 'unPaid':
        return '- ERROR'
      default:
        return ''
    }
  }
  const { submit, loading: LoadingApprove } = approveInvoice(() => {
    setIsExit(false)
    fullRefetch()
  }, console.log)
  const { submit: submitProcess, loading: LoadingProcess } = processInvoice(() => {
    setIsExit(false)
    fullRefetch()
  }, console.log)
  const onSubmit = (action) => {
    switch (action) {
      case 'approve':
        submit({ invoiceId })
        break
      default:
        if (data.invoice.issuedInvoices > 0) {
          if (data.invoice.issuedInvoices === 1) {
            if (data.invoice.issuedInvoices[0]?.paymentMethod !== data.invoice?.userId.paymentMethod.kind) {
              showAlert({ text: "The user payment method is different from issue invoice on stripe", status: 'error', onClick: () => { } }, dispatch)
              return
            }
          } else {
            let isBreak = false
            for (let index = 0; index < data.invoice?.issuedInvoices.length; index++) {
              const element = data.invoice?.issuedInvoices[index];
              if (element.paymentMethod !== data.invoice?.userId.paymentMethod.kind) {
                isBreak = true
                break
              }
            }
            if (isBreak) {
              showAlert({ text: "The user payment method is different from issue invoice on stripe", status: 'error', onClick: () => { } }, dispatch)
              return
            }
          }
        }
        submitProcess({ invoiceId })
    }
  }
  const cancelPayment = (id) => {
    setState({ id: id, state: 'remove' })
    setConfirm(true)
  }
  const waivePayment = (id) => {
    setState({ id: id, state: 'waive' })
    setConfirm(true)
  }
  const { remove } = cancelPaymentItemFromAdmin((data) => {
    // if(data.isPayslipEmpty){
    //   refetch()
    //   toggle()
    //   refetch()
    // } else {
    //   setUpdate(!update)
    refetch()
    //toggle()
    meInvoice()
    // }
  }, console.log)
  const { voidInvoice, loading } = voidInvoiceFromAdmin(() => {
    setConfirm(false)
    fullRefetch()
  })
  const { cancelInvoice, loading: loadingCancelInvoice } = cancelInvoiceFromAdmin(() => {
    setConfirm(false)
    fullRefetch()
  })
  const [sendReminder, { data: dataSend, loading: loadingSend }] = useMutation(
    gql`mutation ($invoiceId: ID!, $stripeInvoiceId: String) {
      sendInvoiceReminder(
          invoiceId: $invoiceId,
          stripeInvoiceId: $stripeInvoiceId
      )
    }`
  )
  useEffect(() => {
    if (dataSend) {
      setConfirm(false)
      showAlert({ text: "The reminder was sent successfuly", status: 'success', onClick: () => { } }, dispatch)
    }
    // eslint-disable-next-line
  }, [dataSend])
  const onExecute = () => {
    console.log('ttt state', state)
    switch (state.state) {
      case 'remove':
        if (!state.id) return
        setConfirm(false)
        remove({ paymentItemId: state.id })
        break
      case 'void':
        voidInvoice({ invoiceId })
        break
      case 'delete':
        cancelInvoice({ invoiceId })
        break
      case 'reminder':
        sendReminder({ variables: { invoiceId, stripeInvoiceId: issues[0].stripeInvoiceId } })
        break
      case 'reminderOff':
        sendReminder({ variables: { invoiceId, stripeInvoiceId: null } })
        break
      default:
        if (!state.id) return
        setConfirm(false)
        remove({ paymentItemId: state.id })
        break
    }
  }
  const editPayment = (item) => {
    if (['lesson-payment-item', 'custom-payment-item', 'plan-payment-item'].includes(item.kind)) {
      if (item.kind === 'plan-payment-item') {
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'payments.editPaymentPlan',
          data: {
            isOpen: true,
            paymentId: item.id,
            kind: item.kind,
            invoiceId: invoiceId
          }
        })
      } else if (item.kind === 'custom-payment-item') {
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'payments.editPaymentCustom',
          data: {
            isOpen: true,
            paymentId: item.id,
            kind: item.kind
          }
        })
      } else {
        // lesson-payment-item
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'payments.editPaymentLesson',
          data: {
            isOpen: true,
            paymentId: item.id,
            kind: item.kind
          }
        })
      }
    }
  }
  const editInvoice = () => {
    if (invoiceId) {
      toggle()
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'payments.editInvoice',
        data: {
          isOpen: true,
          invoiceId: invoiceId
        }
      })
    }
  }
  const addRecord = () => {
    if (invoiceId) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'payments.newRecord',
        data: {
          isOpen: true,
          invoiceId: invoiceId,
          amount: paidManual
        }
      })
    }
  }

  const changePaymentMethod = () => {
    if (data && data?.invoice?.userId) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'payments.changePaymentMethod',
        data: {
          isOpen: true,
          userId: data?.invoice?.userId?.id,
          paymentMethod: data?.invoice?.userId?.paymentMethod,
          refetch:fullRefetch
        }
      })
    }
  }

  const sendInvoiceReminder = () => {
    if (issues.length === 0) {
      setState({ id: 0, state: 'reminderOff' })
      setConfirm(true)
    } else {
      const issuesUnpaid = issues.filter(i => i.status === 'unpaid')
      if (issuesUnpaid.length === 0) return
      if (issuesUnpaid.length === 1) {
        setState({ id: 0, state: 'reminder' })
        setConfirm(true)
      } else {
        alert('it is not implemeted still for multiples invoives issues')
      }
    }
  }
  const makeRefund = (issue) => {
    if (invoiceId) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'payments.makeRefund',
        data: {
          isOpen: true,
          invoice: invoiceId,
          issue: issue,
        }
      })
    }
  }
  const voidAnInvoice = () => {
    setState({ id: invoiceId, state: 'void' })
    setConfirm(true)
  }
  const cancelAnInvoice = () => {
    setState({ id: invoiceId, state: 'delete' })
    setConfirm(true)
  }
  const fullRefetch = () => {
    meInvoice()
    refetch()
  }
  const paymentNameManual = {
    cash: {
      name: translate('invoices.payments.cash')
    },
    check: {
      name: translate('invoices.payments.check')
    },
    creditCard: {
      name: translate('invoices.payments.creditcard')
    },
    debitCard: {
      name: translate('invoices.payments.debitcard')
    },
    bankTransfer: {
      name: translate('invoices.payments.banktransfer')
    },
    payPal: {
      name: translate('invoices.payments.paypal')
    },
    zelle: {
      name: translate('invoices.payments.zelle')
    },
    other: {
      name: translate('invoices.payments.other')
    }
  }
  const paymentName = {
    bankCredit: {
      name: translate('student.modal.payments.credit')
    },
    bankDebit: {
      name: translate('student.modal.payments.debit')
    },
    manual: {
      name: translate('student.modal.payments.manual')
    },
    creditCard: {
      name: translate('student.modal.payments.card')
    },
    zelle: {
      name: translate('student.modal.payments.zelle')
    }
  }
  const callExit = () => {
    refetch()
    toggle()
  }
  const isPaidManual = useMemo(() => {
    if (!data) return false
    if (data.invoice?.manualPayments) {
      const paided = data.invoice.manualPayments.reduce((acum, item) => { return item.amount + acum }, 0)
      let amountPaid = data.invoice?.paymentItems.reduce((accumulator, currentValue) => { return accumulator + (currentValue.percentUnpaid === 0 ? currentValue.amount : (currentValue.amount - currentValue.amount * (currentValue.percentUnpaid / 100))) }, 0)
      if (data.invoice?.taxFee > 0) {
        amountPaid = (amountPaid * (1 + (data.invoice?.taxFee / 100))).toFixed(2)
      }
      if (Number(paided).toFixed(2) === Number(amountPaid).toFixed(2) && paided > 0) {
        setMethod(paymentNameManual[data.invoice.manualPayments[0].paymentMethod]?.name)
        return true
      } else {
        return false
      }
    }
    return false
    // eslint-disable-next-line
  }, [data])

  const copyToClipboard = async (copyText) => {
    if (!copyText) {
      return;
    }
    setTimeout(async () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(copyText).then(() => {
        showAlert({ text: "The link was copied successfully", status: 'success'}, dispatch)
      }).catch((error) => {
        showAlert({ text: `Error copying the link: ${error}`, status: 'error' }, dispatch)
      })
    }
    }, 100);
  };

  const [getInvoiceLink] = useGetInvoiceLink()
  const handleCopyInvoiceLink = async (invoiceId) => {
    getInvoiceLink({ variables: { invoiceId } }).then(
      (resp) => {
        const linkToInvoice = `${process.env.REACT_APP_LIVEPREP_URL || 'http://liveprep.zinkerz.com'}/profile/payments?invoiceId=${invoiceId}&nologin=1&token=${resp?.data?.getInvoiceLink}`
        copyToClipboard(linkToInvoice)
      },
      (error) => {
        console.error(error)
      }
    )
  }

  return (
    <>
      {
        confirm && (
          <ConfirmModal
            className='m-4'
            toggle={() => setConfirm(false)}
            isOpen
            loading={loading || loadingSend || loadingCancelInvoice}
            title={state.state === 'remove' ? translate('payments.modal.removepayment.title') : state.state === 'void' ? translate('payments.modal.voidinvoice.title') : state.state === 'delete' ? translate('payments.modal.cancelInvoice.title') : state.state === 'reminder' ? translate('payments.modal.reminder.titleone') : state.state === 'reminderOff' ? translate('payments.modal.reminder.titleone') : translate('payments.modal.ignorepayment.title')}
            body={state.state === 'remove' ? translate('payments.modal.removepayment.bodyconfirm') : state.state === 'void' ? translate('payments.modal.voidinvoice.bodyconfirm') : state.state === 'delete' ? translate('payments.modal.cancelInvoice.bodyconfirm') : state.state === 'reminder' ? translate('payments.modal.reminder.bodyone') : state.state === 'reminderOff' ? translate('payments.modal.reminder.bodyone') : translate('payments.modal.ignorepayment.bodyconfirm')}
            confirmAction={() => { onExecute() }}
            cancelAction={() => setConfirm(false)}
            confirmText={state.state === 'remove' ? translate('payments.modal.removeitem.confirm') : state.state === 'void' ? translate('payments.modal.voidinvoice.confirm') : state.state === 'delete' ? 'delete' : state.state === 'reminder' ? translate('payments.modal.reminder.confirmone') : state.state === 'reminderOff' ? translate('payments.modal.reminder.confirmone') : translate('payments.modal.ignorepayment.confirm')}
            cancelText={translate('plans.modal.cancelplan.cancel')}
          />
        )
      }
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        className={`modal-sidebar modal-colored ${data ? getStatus(data.invoice, 'modal') : 'scheduled'}-modal modal-desktop-half`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header px-3 px-lg-7 px-lg-5 px-xl-7 d-flex flex-column'>
            <InvoiceCard
              type={data ? getStatus(data.invoice, 'invoice') : 'draft'}
              textTop={'End of ' + data ? moment(data?.invoice?.to).format('MMMM') : ''}
              text={`${data ? getTop(data.invoice?.status) : ''}`}
              typePayment={<IconClose size={20} onClick={toggle} />}
              className='mt-1 px-0'
              amount={data ? (data.invoice?.status === 'paid' ? data.invoice?.amounts.amount : parseFloat(paidSoFar) > 0 ? data.invoice?.amounts.amount - paidSoFar : data.invoice?.amounts.amount) : 0}
              of={!data ? '' : data.invoice?.status !== 'paid' ? 'of $' + formatNumber(data.invoice?.amounts.amount) : ''}
              invoice={`${data ? 'INV-' + String(data.invoice?.invoiceNumber).padStart(5, 0) : ''}`}
              refund={refunded}
            />
            <ListItem
              className={`mb-2 border-top border-bottom w-100 ${data && getStatus(data.invoice, 'modal') === 'error' ? 'color-be' : data && getStatus(data.invoice, 'modal') === 'paid' ? 'color-bp' : data && getStatus(data.invoice, 'modal') === 'canceled' ? 'color-bc' : null}`}
              Left={<span>{translate('misc.paymentMethod')}</span>}
              Right={
                <span>
                  {isPaidManual ? `(M) ${method}` : ''}
                  {data && !isPaidManual && data.invoice?.userId.paymentMethod ? data.invoice?.userId.paymentMethod.kind === 'creditCard' ? data.invoice?.userId.paymentMethod.brand.charAt(0).toUpperCase() + data.invoice?.userId.paymentMethod.brand.slice(1) + ' ' + data.invoice?.userId.paymentMethod.last4 : (data.invoice?.userId.paymentMethod.kind === 'bankDebit' ? data.invoice?.userId.paymentMethod.accountInfo.last4 + ' ' + data.invoice?.userId.paymentMethod.accountInfo.bankName : data.invoice?.userId.paymentMethod.kind === 'zelle' ? 'Zelle' : 'Manual') : ''}
                  {data && data.invoice?.status !== 'paid' && <IconRight className='ml-1' color={`${data && getStatus(data.invoice, 'modal') === 'error' ? '#FF3E1D' : data && getStatus(data.invoice, 'modal') === 'paid' ? '#81B023' : data && getStatus(data.invoice, 'modal') === 'canceled' ? '#778699' : data && getStatus(data.invoice, 'modal')  === 'upcoming' ? '#eaf2ff' : '' }`} />}
                </span>
              }
              onClick={() => { if (data && data.invoice?.status !== 'paid') changePaymentMethod(); }}
            />
            {data && (data.invoice?.status === 'unPaid' || data.invoice?.status === 'canceled') &&
              <div style={{ display: 'flex', flexDirection: 'row', aligItems: 'center' }}>
                <span style={{ marginRight: 100 }}>{translate('payments.viewinvoice.errorunpaid')}</span>
                <IconAlert size={30} className={`${data.invoice?.status === 'unPaid' ? 'text-error' : 'text-body-bg-secondary'}`} />
              </div>}
            {data && data.invoice?.status === 'paid' && (<ListItem
              className='mb-2 w-100'
              Left={<span style={{ color: '#81B023' }}>Invoice paid on {moment(paidOn).format('MMM DD, YYYY hh:mm a')}</span>}
              Right={
                <span>
                  {/* <IconSuccess className='ml-1' color='#A7E521' size={16} /> */}
                  <img src='https://media.zinkerz.com/email/20210422/payment-success.png' className='ml-1' width={28} height={28} alt='success paid' />
                </span>
              }
            />)}
            <span>invoiceId: {invoiceId}</span>
          </div>
        }
      >

        {
          data ? (
            <div className='d-flex flex-column'>
              <div className='px-7'>
                <div className='borderTop mt-5' />
              </div>
              <div className='px-4'>
                <PlanStudent form={form} setForm={setForm} title={false} first={false} invoice edit={false} />
              </div>
              <div className='px-7'>
                <div className='borderTop' />
              </div>
              {
                !data
                  ? <></>
                  : data?.invoice?.category === 'livePrep'
                    ?
                    <div className='d-flex flex-row mt-5 px-7'>
                      <IconLesson />
                      <span className='ml-2'>{translate('payments.livePre')}</span>
                    </div>
                    :
                    <div className='d-flex flex-row mt-5 px-7'>
                      <img src="https://media.zinkerz.com/email/20210422/counseling.png" style={{ height: 20, width: 20 }} alt="counseling lesson" />
                      <span className='ml-2'>{translate('payments.counseling')}</span>
                    </div>
              }
              <div className='px-7'>
                <div className='borderTop mt-5' />
              </div>
              <div className='d-flex flex-row justify-content-between mt-3 px-7'>
                <span className='text-gray'>Item</span>
                <span className='text-gray'>Amount</span>
              </div>
              {
                data && form?.items?.map(element => {
                  let opciones
                  if (element.kind === 'lesson-payment-item') {
                    opciones = (
                      <div className='d-flex flex-column my-2 ml-3' style={{ width: '200px' }}>
                        <span className='primary d-flex flex-row align-items-center' onClick={() => { editPayment(element) }}>
                          <IconEdit size={14} />
                          <div className='ml-2 text-secondaryy'>Edit Payment</div>
                        </span>
                        <span className='warning d-flex flex-row align-items-center' onClick={() => { waivePayment(element.id) }}>
                          <IconSignPlus size={14} />
                          <div className='ml-2 text-secondaryy'>Waive Payment</div>
                        </span>
                        {/* <span className='warning d-flex flex-row align-items-center'>
                          <IconPenalize size={14} />
                          <div className='ml-2 text-secondaryy'>Penalize payment</div>
                        </span> */}
                        {/* <span className='warning d-flex flex-row align-items-center cursor' onClick={() => { cancelPayment(element.id) }}>
                          <IconErase size={14} />
                          <div className='ml-2 text-secondaryy'>Remove payment</div>
                        </span> */}
                      </div>
                    )
                  } else {
                    opciones = (
                      <div className='d-flex flex-column my-2 ml-3' style={{ width: '200px' }}>
                        <span className='primary d-flex flex-row align-items-center' onClick={() => { editPayment(element) }}>
                          <IconEdit size={14} />
                          <div className='ml-2 text-secondaryy'>Edit Payment</div>
                        </span>
                        {/* <span className='warning d-flex flex-row align-items-center'>
                          <IconPenalize size={14} />
                          <div className='ml-2 text-secondaryy'>Penalize payment</div>
                        </span> */}
                        <span className='warning d-flex flex-row align-items-center cursor' onClick={() => { cancelPayment(element.id) }}>
                          <IconErase size={14} />
                          <div className='ml-2 text-secondaryy'>Remove payment</div>
                        </span>
                      </div>
                    )
                  }
                  return (
                    <div
                      key={`item-${element.id}`}
                      className='d-flex flex-row justify-content-between align-items-center hover-button'
                    >
                      <div className='w-100 px-7'>
                        <div className='mt-3 mb-3' />
                        <div className='d-flex flex-row justify-content-between'>
                          <div>
                            <span className='text-bold'>
                              {
                                element.category === 'Counseling Payment' && ['otherFixed'].includes(element.type)
                                  ? 'Counseling'
                                  : element.category === 'counseling' && ['otherFixed'].includes(element.type) ? getPaymentsType('counselingPayment', element.kind)
                                  : getPaymentsType(element.type, element.kind)
                              }
                            </span>
                            <span className='text-gray'>
                              {
                                element.percentDiscount > 0
                                  ? ' (' + element.percentDiscount.toFixed(2) + '% Discount)'
                                  : ''
                              }
                            </span>
                          </div>
                          <span className='text-gray'>${formatNumber(element.amount)}</span>
                        </div>
                        <div className='d-flex flex-row justify-content-between'>
                          <div className='text-gray text-truncate' style={{ width: '100%' }}>
                            {element.generatedDescription ?? element.description}
                          </div>
                          {element.percentDiscount > 0 ? <div className='text-gray text-through text-right' style={{ width: 100 }}>${formatNumber(element.amountWithoutDiscount)}</div> : <div style={{ width: 100 }}>{' '}</div>}
                        </div>
                        <div className='borderTop mt-3' />
                      </div>
                      <div className={`${!['paid', 'canceled'].includes(data.invoice?.status) ? 'hover-button--on' : 'hover-button--off'}`}>
                        <div className='znk-table-dropdown-custom dropdown-menu-width-content pr-0'>
                          <DropDown
                            direction='down'
                            fixedWidth
                            hoverEnabled={false}
                            options={[
                              {
                                component: opciones
                              }
                            ]}
                          >
                            <IconOptions style={{ transform: 'rotate(90deg)' }} color='#696CFF' />
                          </DropDown>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              <div className='px-7'>
                <div className='d-flex flex-row justify-content-between mt-3'>
                  <div>
                    <span>Sub Total</span>
                  </div>
                  <span>$ {data && formatNumber(data.invoice?.amounts.subTotal)}</span>
                </div>
                <div className='borderTop mt-3' />
              </div>
              <div className='px-7'>
                <div className='d-flex flex-row justify-content-between mt-3'>
                  <div>
                    <span>{data && data.invoice?.taxFee === 0 ? 'Tax 0%' : `Credit Card Processing Fee at ${data.invoice?.taxFee}%`}</span>
                  </div>
                  <span>${data && formatNumber(data.invoice?.amounts.taxAmount)}</span>
                </div>
                <div className='borderTop mt-3' />
              </div>
              <div className='px-7'>
                <div className='d-flex flex-row justify-content-between mt-3'>
                  <div>
                    <span className='font-weight-bold'>Total Price</span>
                  </div>
                  <span className='font-weight-bold'>${data && formatNumber(data.invoice?.amounts.amount)}</span>
                </div>
                <div className='borderTop mt-3' />
              </div>
              <div className='px-7 mb-2'>
                {data && !['draft', 'paid', 'canceled'].includes(data.invoice?.status) && (
                  // <div>
                  //   <div className='d-flex flex-row justify-content-between mt-3 px-7 cursor mt-7' onClick={() => {addRecord()}}>
                  //     <div className='d-flex flex-row align-items-center'>
                  //       <IconCircleDolar size={16} color='#696CFF' className='mr-2' />
                  //       <span className='text-primary'>Record Manual Payment</span>
                  //     </div>
                  //     <IconRight size={16} />
                  //   </div>
                  //   <div className='px-7'>
                  //     <div className='borderTop mt-3' />
                  //   </div>
                  // </div>
                  <ListItem
                    hasHover
                    className={`hover-no-border px-2`}
                    showBottomLine
                    onClick={() => {
                      addRecord()
                    }}
                    Left={
                      <div className='d-flex flex-row align-items-center'>
                        <IconCircleDolar size={16} color='#696CFF' className='mr-2' />
                        <span className='text-primary'>Record Manual Payment</span>
                      </div>
                    }
                    Right={<IconRight size={16} />}
                  />
                )}
                {data && !['paid', 'canceled'].includes(data.invoice?.status) && (
                  // <div>
                  //   <div className='d-flex flex-row justify-content-between mt-3 px-7 cursor' onClick={() => {sendInvoiceReminder()}}>
                  //     <div className='d-flex flex-row align-items-center'>
                  //       <IconEmail size={16} color='#696CFF' className='mr-2' />
                  //       <span className='text-primary'>Send Reminder</span>
                  //     </div>
                  //     <IconRight size={16} />
                  //   </div>
                  //   <div className='px-7'>
                  //     <div className='borderTop mt-4' />
                  //   </div>
                  // </div>
                  <ListItem
                    hasHover
                    className={`hover-no-border px-2`}
                    showBottomLine
                    onClick={() => {
                      sendInvoiceReminder()
                    }}
                    Left={
                      <div className='d-flex flex-row align-items-center'>
                        <IconEmail size={16} color='#696CFF' className='mr-2' />
                        <span className='text-primary'>Send Reminder</span>
                      </div>
                    }
                    Right={<IconRight size={16} />}
                  />
                )}
                {
                  data && !['canceled', 'paid'].includes(data.invoice?.status) && (
                    <ListItem
                      hasHover
                      className={`hover-no-border px-2`}
                      showBottomLine
                      onClick={() => {
                        handleCopyInvoiceLink(data?.invoice?.id)
                      }}
                      Left={<span className='text-primary'><IconLink className={'text-primary'} size={16}/> {translate('payments.viewinvoice.copyInvoiceLink') || "Copy Invoce Link"}</span>}
                      Right={<IconRight size={16} />}
                    />
                  )
                }
                {data && !['paid', 'canceled'].includes(data.invoice?.status) && (
                  // <div>
                  //   <div className='d-flex flex-row justify-content-between mt-3 px-7 cursor' onClick={() => {editInvoice()}}>
                  //     <div className='d-flex flex-row align-items-center'>
                  //       <IconEdit size={16} color={`${data && data.invoice?.status !== 'unPaid' ? '#696CFF' : '#778699'}`} className='mr-2' />
                  //       <span className={`${data && data.invoice?.status !== 'unPaid' ? 'text-primary' : 'text-gray'}`}>Edit Invoice</span>
                  //     </div>
                  //     <IconRight size={16} color={`${data && data.invoice?.status !== 'unPaid' ? '#696CFF' : '#778699'}`} />
                  //   </div>
                  //   <div className='px-7'>
                  //     <div className='borderTop mt-4' />
                  //   </div>
                  // </div>
                  <ListItem
                    hasHover
                    className={`hover-no-border px-2`}
                    showBottomLine
                    onClick={() => {
                      editInvoice()
                    }}
                    Left={
                      <div className='d-flex flex-row align-items-center'>
                        <IconEdit size={16} color={`${data && data.invoice?.status !== 'unPaid' ? '#696CFF' : '#778699'}`} className='mr-2' />
                        <span className={`${data && data.invoice?.status !== 'unPaid' ? 'text-primary' : 'text-gray'}`}>Edit Invoice</span>
                      </div>
                    }
                    Right={<IconRight size={16} />}
                  />
                )}
                {data && !['canceled', 'paid'].includes(data.invoice?.status) && (
                  // <div>
                  //   <div className='d-flex flex-row justify-content-between mt-3 px-7 cursor' onClick={() => {voidAnInvoice()}}>
                  //     <div className='d-flex flex-row align-items-center'>
                  //       <IconEmail size={16} color='#FF3E1D' className='mr-2' />
                  //       <span className='text-error'>Void Invoice</span>
                  //     </div>
                  //     <IconRight size={16} />
                  //   </div>
                  //   <div className='px-7'>
                  //     <div className='borderTop mt-4' />
                  //   </div>
                  // </div>
                  <ListItem
                    hasHover
                    className={`hover-no-border px-2`}
                    showBottomLine
                    onClick={() => {
                      voidAnInvoice()
                    }}
                    Left={
                      <div className='d-flex flex-row align-items-center'>
                        <IconEmail size={16} color='#FF3E1D' className='mr-2' />
                        <span className='text-error'>Void Invoice</span>
                      </div>
                    }
                    Right={<IconRight size={16} />}
                  />
                )}
                {data && !['canceled', 'paid', 'unPaid'].includes(data.invoice?.status) && (
                  // <div>
                  //   <div className='d-flex flex-row justify-content-between mt-3 px-7 cursor'>
                  //     <div className='d-flex flex-row align-items-center'>
                  //       <IconSignPlus size={16} color={`${data && data.invoice?.status === 'draft' && !data.invoice?.isManual ? '#FF3E1D' : '#FF3E1D'}`} className='mr-2' />
                  //       <span className={`${data && data.invoice?.status === 'draft' && !data.invoice?.isManual ? 'text-error' : 'text-error'}`}>Delete Invoice</span>
                  //     </div>
                  //     <IconRight size={16} />
                  //   </div>
                  //   <div className='px-7'>
                  //     <div className='borderTop mt-4' />
                  //   </div>
                  // </div>

                  <ListItem
                    hasHover
                    className={`hover-no-border px-2`}
                    showBottomLine
                    onClick={() => {
                      cancelAnInvoice()
                    }}
                    Left={
                      <div className='d-flex flex-row align-items-center'>
                        <IconSignPlus size={16} color={`${data && data.invoice?.status === 'draft' && !data.invoice?.isManual ? '#FF3E1D' : '#FF3E1D'}`} className='mr-2' />
                        <span className={`${data && data.invoice?.status === 'draft' && !data.invoice?.isManual ? 'text-error' : 'text-error'}`}>Delete Invoice</span>
                      </div>
                    }
                    Right={<IconRight size={16} />}
                  />
                )}
                {data && data.invoice?.status === 'paid' && data.invoice?.issuedInvoices.length <= 1 && (
                  // <div>
                  //   <div
                  //     className='d-flex flex-row justify-content-between mt-3 px-7 cursor align-items-center'
                  //     onClick={() => { makeRefund(data.invoice?.issuedInvoices[0]) }}
                  //   >
                  // <div className='d-flex flex-row align-items-center'>
                  //   <IconDolarRefresh size={16} color='#778699' className='mr-2' />
                  //   <span className='text-bold'>Issue Refund</span>
                  // </div>
                  //     <IconRight size={16} />
                  //   </div>
                  //   <div className='px-7'>
                  //     <div className='borderTop mt-4' />
                  //   </div>
                  // </div>
                  <ListItem
                    hasHover
                    className={`hover-no-border px-2`}
                    showBottomLine
                    onClick={() => {
                      makeRefund(data.invoice?.issuedInvoices[0])
                    }}
                    Left={
                      <div className='d-flex flex-row align-items-center'>
                        <IconDolarRefresh size={16} color='#778699' className='mr-2' />
                        <span className='text-bold'>Issue Refund</span>
                      </div>
                    }
                    Right={<IconRight size={16} />}
                  />

                )}
               <ReceiptComponent item={data?.invoice}/>
              </div>
              {
                data && data.invoice?.manualPayments.length > 0 && (
                  <div className='mt-3 px-7'>
                    {
                      data && data.invoice?.manualPayments?.map(
                        (payment, index) => {
                          return (
                            <Fragment>
                              <div
                                key={`manual-${index}`}
                                className={`znk-invoice-row paid`}
                              >
                                <span>
                                  <IconCircleDolar />
                                  <div className='ml-3'>{moment(String(payment.lastProcessedAt).split('T')[0]).format('MM/DD/YYYY')}</div>
                                </span>
                                <span className='text-color'><div className='w-100'>(M) {paymentNameManual[payment.paymentMethod]?.name}</div></span>
                                <span className='font-weight-bold text-color justify-content-end' style={{ marginRight: 10 }}>${formatNumber(payment.amount)}</span>
                                <span className='flex-grow-0'>
                                  <div className='znk-table-dropdown-custom dropdown-menu-width-content pr-0'>
                                    <DropDown
                                      direction='down'
                                      fixedWidth
                                      hoverEnabled={false}
                                      options={[
                                        {
                                          component: null
                                        }
                                      ]}
                                    >
                                      <IconOptions style={{ transform: 'rotate(90deg)', marginRight: 10 }} color='rgba(37, 64, 80, 0.6)' />
                                    </DropDown>
                                  </div>
                                </span>
                              </div>
                            </Fragment>
                          )
                        }
                      )
                    }
                  </div>
                )
              }
              {
                data && data.invoice?.issuedInvoices.length > 0 && (
                  <div className='px-7 mb-7'>
                    {
                      // data && data.invoice.issuedInvoices.filter(e => e.status === 'paid').map(
                      data && data.invoice?.issuedInvoices?.filter(e => !stripeIds.includes(e.stripeInvoiceId)).map(
                        (issue, index) => {
                          const opciones = (
                            <div className={`d-flex flex-column ml-1 ${index > 0 ? 'my-1' : ''}`} style={{ width: '170px' }}>
                              <span className='primary d-flex flex-row' onClick={() => { makeRefund(issue) }}>
                                <IconDolarRefresh size={14} color='#72809d' />
                                <div className='ml-2 text-secondary'>{translate('payments.makerefund')}</div>
                              </span>
                            </div>
                          )
                          return (
                            <Fragment>
                              <div
                                key={issue.stripeInvoiceId}
                                className={`znk-invoice-row ${issue.status === 'paid' ? 'paid' : issue.status === 'open' ? 'unPaid' : 'canceled'}`}
                                style={{ marginTop: `${index === 0 ? '0px' : ''}` }}
                              >
                                <span>
                                  <IconCircleDolar />
                                  <div className='ml-3'>{moment(String(issue.lastProcessedAt).split('T')[0]).format('MM/DD/YYYY')}</div>
                                </span>
                                <span className='text-color'><div className='w-100'>{paymentName[issue.paymentMethod]?.name}</div></span>
                                <span className='font-weight-bold text-color justify-content-end' style={{ marginRight: 10 }}>${formatNumber(issue.amount)}</span>
                                <span className='flex-grow-0'>
                                  <div className='znk-table-dropdown-custom dropdown-menu-width-content pr-0'>
                                    <DropDown
                                      direction='down'
                                      fixedWidth
                                      hoverEnabled={false}
                                      options={[
                                        {
                                          component: issue.status === 'paid' ? opciones : null
                                        }
                                      ]}
                                    >
                                      <IconOptions style={{ transform: 'rotate(90deg)', marginRight: 10 }} color='rgba(37, 64, 80, 0.6)' />
                                    </DropDown>
                                  </div>
                                </span>
                              </div>
                              {
                                issue?.issuedCreditNotes?.map(
                                  refund => {
                                    return (
                                      <div
                                        key={refund.creditNoteId}
                                        className='znk-invoice-row upcomming'
                                      >
                                        <span>
                                          <IconDolarRefresh />
                                          <div className='ml-3'>{moment(new Date(refund.stripeInfo.created * 1000)).format('MM/DD/YYYY')}</div>
                                        </span>
                                        <span className='text-color'><div className='w-100'>{translate('payments.refund')}</div></span>
                                        <span className='font-weight-bold text-color justify-content-end' style={{ marginRight: 10 }}>${formatNumber(refund.amount)}</span>
                                        <span className='flex-grow-0'>
                                          <div className='znk-table-dropdown-custom dropdown-menu-width-content pr-0'>
                                            {/* <IconOptions style={{ transform: 'rotate(90deg)', marginRight: 10 }} color='rgba(37, 64, 80, 0.6)' /> */}
                                            <DropDown
                                              direction='down'
                                              fixedWidth
                                              hoverEnabled={false}
                                              options={[
                                                {
                                                  component: []
                                                }
                                              ]}
                                            >
                                              <IconOptions style={{ transform: 'rotate(90deg)' }} color='#696CFF' />
                                            </DropDown>
                                          </div>
                                        </span>
                                      </div>
                                    )
                                  }
                                )
                              }
                            </Fragment>
                          )
                        }
                      )
                    }
                  </div>
                )
              }
              {
                data && !['paid', 'canceled'].includes(data.invoice?.status) && data.invoice?.userId?.paymentMethod && !['manual', 'zelle'].includes(data.invoice?.userId?.paymentMethod?.kind) && (
                  <div className='px-7'>
                    <StickyButton>
                      <div className='m-0 p-0 row my-5'>
                        <Button
                          color='primary'
                          activity={LoadingApprove || LoadingProcess}
                          className={
                            isEdit ? 'col-11' : 'col-9'
                          }
                          label={(data.invoice?.status === 'draft' ? 'Approve Invoice' : 'Process Invoice')}
                          onClick={() => {
                            onSubmit(data.invoice?.status === 'draft' ? 'approve' : 'process')
                          }}
                          style={{ borderRadius: `${isEdit ? '16px' : '16px 16px 16px 16px'}` }}
                        />
                      </div>
                    </StickyButton>
                  </div>)
              }
              {
                data && data.invoice?.status === 'draft' && data.invoice?.userId?.paymentMethod && ['manual', 'zelle'].includes(data.invoice?.userId?.paymentMethod?.kind) && (
                  <div className='px-7'>
                    <StickyButton>
                      <div className='m-0 p-0 row my-5'>
                        <Button
                          color='primary'
                          activity={LoadingApprove || LoadingProcess}
                          className={
                            isEdit ? 'col-11' : 'col-9'
                          }
                          label={data.invoice?.status === 'draft' ? 'Approve Invoice' : 'Process Invoice'}
                          onClick={() => {
                            onSubmit(data.invoice?.status === 'draft' ? 'approve' : 'process')
                          }}
                          style={{ borderRadius: `${isEdit ? '16px' : '16px 16px 16px 16px'}` }}
                        />
                      </div>
                    </StickyButton>
                  </div>)
              }
              <div className='px-7 mb-7'>
                <div className='d-flex flex-row justify-content-between align-items-center mt-7'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex flex-row'>
                      <h5 className='font-weight-bold h14 text-gray'>Created</h5><h5 className='ml-2 font-weight-light h14 text-gray'>{data ? `at ${moment(data.createdInfo?.succeded ?? data.invoice?.createdAt).format('MMM DD, YYYY hh:mm a')}  ${data.invoice?.createdInfo ? `by ${data.invoice?.createdInfo?.firstName} ${data.invoice?.createdInfo?.lastName}` : ''}` : ''}</h5>
                    </div>
                    <div className='d-flex flex-row'>
                      <h4 className='font-weight-bold h14 text-gray'>Updated</h4><h4 className='ml-2 font-weight-light h14 text-gray'>{data ? `at ${moment(data.invoice?.updatedAt).format('MMM DD, YYYY hh:mm a')}   ${data.invoice?.updatedInfo ? `by ${data.invoice?.updatedInfo?.firstName} ${data.invoice?.updatedInfo?.lastName}` : ''}` : ''}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
            : (
              <div className='px-7 mt-5'>
                <Skeleton count={10} height={40} />
              </div>
            )
        }
      </Modal>
      <EditPaymentItem refetch={fullRefetch} setExit={callExit} />
      <EditCustomPaymentItem refetch={fullRefetch} />
      <EditLessonPaymentItem refetch={fullRefetch} />
      <NewRecord refetch={meInvoice} fullRefetch={fullRefetch} />
      <MakeRefund refetch={meInvoice} />
      <ChangePaymentMethod refetch={meInvoice} />
    </>
  )
}

export default ViewInvoice
