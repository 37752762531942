import React, { useEffect, useMemo } from 'react'
import {InputSelect} from '@Knowledge-OTP/znk-ui-components' 
import {TabContent, TabPane} from 'reactstrap'
import RenderInputOption from '../../Components/RenderInputOption'
import useGetLazyPdfTemplate from '../../../../../PdfEditor/Model/getLazyPdfTemplate'

const proposalOptions = [
    {value: 'maleNonSenior', label: 'Male-Non-Senior'},
    {value: 'femaleNonSenior', label: 'Female-Non-Senior'},
    {value: 'maleSenior', label: 'Male-Senior'},
    {value: 'femaleSenior', label: 'Female-Senior'},
    {value: 'maleTransfer', label: 'Male-Transfer'},
    {value: 'femaleTransfer', label: 'Female-Transfer'},
    {value: 'maleGraduate', label: 'Male-Graduate'},
    {value: 'femaleGraduate', label: 'Female-Graduate'},
    {value: 'maleHighSchool', label: 'Male-High-School'},
    {value: 'femaleHighSchool', label: 'Female-High-School'},
    // {value: 'maleLastMin', label: 'Male-Last-Min'},
    // {value: 'femaleLastMin', label: 'Female-Last-Min'}
]
const gradeNonSenior = [
    {value: '_8th', label: '8th'},
    {value: '_9th', label: '9th'},
    {value: '_10th', label: '10th'},
    {value: '_11th', label: '11th'},
].map(({value}) => value)
const gradeSenior = [
    {value: '_12th', label: '12th'},
].map(({value}) => value)

const typeOptions = ['graduate', 'transfer'] //tipos de plan que no consideran opciones Senior NonSenior ni LastMin


const camelCaseToKey = (text) => {
    const result = text.replace(/([A-Z])/g, "-$1").toLowerCase()
    // const finalResult = result.charAt(0).toLowerCase() + result.slice(1);
    return result
}

const Proposal = (props) => {
    const {className, plan, setData, isEdit, dataPlan} = props
    
    useEffect(() => {
        // PRESELECCIONA PROPOSAL VERSION
        if(plan?.studentUser?.gender && (plan?.studentGrade || plan?.type)) {
            if(typeOptions.includes(plan?.type)) {
                setData(
                    {
                        proposalVersion: 
                            `${plan.studentUser.gender.toLowerCase()}${plan.type.substring(0,1).toUpperCase()+plan.type.substring(1,plan.type.length)}`
                    }
                )
            }
            else if (plan?.studentGrade) {
                if(gradeNonSenior.includes(plan?.studentGrade)) {
                    setData({proposalVersion: `${plan.studentUser.gender.toLowerCase()}NonSenior`})
                }
                else if(gradeSenior.includes(plan?.studentGrade)) {
                    setData({proposalVersion: `${plan.studentUser.gender.toLowerCase()}Senior`})
                }
            }
        }
         // eslint-disable-next-line
    }, [plan?.studentGrade, plan?.studentUser?.gender, plan?.type])


    const [getPdfTemplate, {data}] = useGetLazyPdfTemplate()
    useEffect(() => {
        if(plan?.proposalVersion) {
            getPdfTemplate({
                variables: {
                    // templateId: ID
                    templateKey: camelCaseToKey(plan?.proposalVersion)
                }
            })
        }
        // eslint-disable-next-line
    }, [plan?.proposalVersion])

    const proposalId = useMemo(
        () => {
            return data?.getPdfTemplate?.id
        }, [data]
    )

    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
            <span className={`h4 font-weight-bold text-title pb-3`}>Proposal</span>
            {
                true && (
                <TabContent activeTab={'selected'}>
                    <TabPane tabId={'selected'}>
                        <div className={`d-flex flex-row row`}>
                            <RenderInputOption className={`col-12`} label={`Proposal version`}>
                                <InputSelect
                                    options={proposalOptions}
                                    name={`proposalVersion`}
                                    value={plan.proposalVersion}
                                    onChange={(proposal) => {
                                        setData({proposalVersion: proposal})
                                    }}
                                />
                            </RenderInputOption>
                        </div>
                        <div className={`m-0 p-0 d-flex flex-row justify-content-end`}>
                            <label 
                                className={`h45 btn-link`}
                                onClick={() => {
                                    // redirecciona a amazon cuando plan esta siendo editado
                                    if(isEdit) window.open(`${process.env.AMAZONS3_MEDIA_URL || 'https://s3.eu-west-1.amazonaws.com/media.zinkerz.com/counseling'}/${dataPlan?.studentUser?.studentUserId}/${dataPlan?.id}/proposal-non-sign.pdf`)
                                    //y a pdf-editor cuando el plan está siendo creado y 
                                    else window.open(`${process.env.ADMIN_URL || 'https://admin.zinkerz.com'}/pdf-editor/${proposalId}?hideMenu=true`, "_blank");
                                }}
                            >View PDF proposal</label>
                        </div>
                        
                        {/* <PdfViewer 
                            proposalVersion={plan?.proposalVersion}
                            noUsePlan={!(plan?.id?.length > 0)} 
                            counselingPlanId={plan?.id || null} 
                            isAdmin style={{height: '500px'}} 
                            className={`w-100`} 
                            setDocument={(documentInstance) => {setData({proposalPdfInstance: documentInstance})}}                                    
                        />  */}
                    </TabPane>
                </TabContent>
                )
            }
        </div>
    )
}

export default Proposal