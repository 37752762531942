import React from 'react';
import useTranslation from '../../../../../../i18n/useTranslation';
import { showAlert } from '../../../../../../common/Alert/util';
import { useDispatch } from 'react-redux';

const GoogleDriveFolder = (props) => {
  const { user = {} } = props;
  const baseUrl = 'https://drive.google.com/drive/u/2/folders/';
  const folderDriveId = user?.studentInfo?.folderDriveId;
  let completeUrl = '';
  if (folderDriveId) {
    completeUrl = baseUrl + folderDriveId;
  }

  const { translate } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className='d-flex flex-row justify-content-between align-items-center mt-4'>
      <span className='text-title w-40'>
        {translate('profile.actions.editGeneralInfo.googleDriveFolder')}
      </span>
      <span className='text-gray d-flex flex-row align-items-center justify-content-end w-50'>
        <span
          onClick={() => {
            if (folderDriveId) {
              navigator.clipboard.writeText(completeUrl).then(() => {
                showAlert({ text: 'Copy!', status: 'success' }, dispatch);
              });
              showAlert({ text: completeUrl, status: 'success' }, dispatch);
            }
          }}
          className='text-truncate cursor-pointer'
          title={completeUrl}
        >
          {completeUrl}
        </span>
      </span>
    </div>
  );
};

export default GoogleDriveFolder;
