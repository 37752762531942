import React, { useRef, useState, useMemo } from 'react'
import {
    TableTask,
    // IconStar,
    Button,
    IconSearch,
    useWindowDimensions,
    IconProgram,
    IconSignPlus,
    IconFilter
} from '@Knowledge-OTP/znk-ui-components'
import {Badge} from 'reactstrap'
import Pagination from '../../common/Pagination'
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    TabPane, TabContent
} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import useColumns from './Columns'
import { useDispatch, useSelector } from 'react-redux'
// import StatusesFilter from '../Plans/Filters/Statuses'
// import TypesFilter from './Filters/Types'
// import GraduationFilter from './Filters/Graduation'
// import CountryFilter from './Filters/Country'
import useLessonsQuery from './Model/useLessonsQuery'

//modals
import InfoLesson from '../Calendar/modal/InfoLesson'
import useInfoLesson from '../Calendar/modal/InfoLesson/useInfoLesson'

import OpenLessonModalFromUrl from '../../common/OpenLessonModalFromUrl'
import DuplicateLesson from '../Calendar/modal/DuplicateLesson'
import MakeUpLesson from '../Calendar/modal/MakeUpLesson'

import NewLesson from '../Calendar/modal/NewLesson'
import useNewLesson from '../Calendar/modal/NewLesson/useNewLesson'

import EditPayment from '../Calendar/modal/EditPayment'
import SetPendingLesson from '../Calendar/modal/SetPendingLesson'
import RescheduledLesson from '../Calendar/modal/RescheduledLesson'
import PenalizeLesson from '../Calendar/modal/PenalizeLesson'
import ChangeTimezone from '../Calendar/modal/ChangeTimezone'
import EditLesson from '../Calendar/modal/EditLesson'
import WspAlert from '../Calendar/modal/WspAlert'
import SendEmailReminder from '../Calendar/modal/SendEmailReminder'
import ScheduledConflict from '../Calendar/Components/ScheduledConflict'
import ConfirmDropRescheduled from '../Calendar/modal/ConfirmDropRescheduled'
import OrientationModal from '../Calendar/modal/OrientationModal'
import ResumeOrientation from '../Calendar/modal/ResumeOrientation'
import ConfirmSetBraincertToLesson from '../Calendar/modal/ConfirmSetBraincertToLesson'
import CancelLesson from '../Calendar/modal/CancelLesson'
import StateFilter from '../Calendar/Sections/Filters/StateFilter'
import StudentFilter from '../Calendar/Sections/Filters/StudentFilter'
import FilterDate from './Filters/Date'
import FilterService from './Filters/Service'

import moment from 'moment-timezone'
import { useHistory } from "react-router-dom"
import _ from "lodash"
import ModalFilterMobile from './Filters/ModalFilterMobile'
import TypeLessonFilter from './Filters/TypeLesson'

export const baseFilterStatus = ['missed', 'incomplete', 'noshow', 'finished', 'attended', 'upcoming', 'inprogress'] 

const shortCutList = [
    {key: 'allLessons', label: 'All Lessons'},
    {key: 'pending', label: 'Pending'},
    {key: 'upcomming', label: 'Upcoming'},
    {key: 'today', label: 'Today'},
    {key: 'inProgress', label: 'In Progress'},
    {key: 'completed', label: 'Completed'},
    {key: 'notShow', label: 'No Show'},
    {key: 'incomplete', label: 'Incomplete'},
]

const LessonsList = () => {

    const [shortCut, setShortCut] = useState('allLessons')
    const { breakWidth } = useWindowDimensions()
    let history = useHistory()
    const searchInput = useRef(null)
    const { open: openInfoLesson } = useInfoLesson()
    const { open: openNewLesson } = useNewLesson()
    const defaultQuery = {
        sortField: 'startDate',
        previous: null,
        next: null,
        limit: 10,
        studentIds: [],
        endDate: null,
        startDate: null,
        planId: null,
        status: ['scheduled', 'finished'],
        /* missed,incomplete,noshow,finished,attended,upcoming,pending,inprogress */
        filterStatus: baseFilterStatus,
        educatorIds: [],
        search: null,
        serviceIds: null,
        typeLessons: null,
        //finished (attended, missed, noshow), scheduled (scheduled, incomplete), pending, draft
        // serviceIds: null,
    }
    const initQuery = {
        ...defaultQuery, startDate: moment().subtract(1, 'week').startOf('isoWeek').toISOString()
    }
    const [query, setQuery] = useState(initQuery)
    const { data, loading, refetch: refetchCalendarQuery } = useLessonsQuery(query)
    const { generateRow, config } = useColumns(refetchCalendarQuery)
    const { lessons, paginatedData } = useMemo(() => {
        if (data?.lessons) {
            const { docs, ...paginatedData } = data.lessons
            return {
                lessons: data?.lessons?.docs || [],
                paginatedData

            }
        }
        return { paginatedData: {}, lessons: [] }
    }, [data])
    const {
        hasNext,
        hasPrevious,
        next,
        previous,
        totalDocs
    } = paginatedData
    const [currentPage, setCurrentPage] = useState(1)
    const [upcommingLessons, setUpcommingLesson] = useState([])
    const dispatch = useDispatch()
    const services = useSelector((state) => state.user.services)
    const orientation = services.find(({ isOrientation }) => isOrientation)

    const getShortcutQuery = (newShortcut) => {
        let newQuery = {}
        switch (newShortcut) {
            case 'pending':
                newQuery = { ...defaultQuery, filterStatus: ['pending'] }
                break


            case 'upcomming':
                newQuery = { ...defaultQuery, filterStatus: ['upcoming'] }
                break



            case 'today':
                newQuery = { ...defaultQuery, startDate: moment().startOf('day').toISOString(), endDate: moment().endOf('day').toISOString() }
                break



            case 'inProgress':
                newQuery = { ...defaultQuery, filterStatus: ['inprogress'] }
                break


            case 'completed':
                newQuery = { ...defaultQuery, filterStatus: ['attended', 'missed'] }
                break


            case 'notShow':
                newQuery = { ...defaultQuery, filterStatus: ['noshow'] }
                break


            case 'incomplete':
                newQuery = { ...defaultQuery, filterStatus: ['incomplete'] }
                break


            default://allLessons
                newQuery = { ...initQuery }
                break
        }
        return newQuery
    }
    const applyShortcut = (newShortcut) => {
        setUpcommingLesson([])
        setCurrentPage(1)
        const newQuery = getShortcutQuery(newShortcut)
        setQuery({ ...newQuery })
        setShortCut(newShortcut)
    }


    const canClearFilters = useMemo(() => {
        const defaultQueryShortcut = getShortcutQuery(shortCut)
        const {
            sortField,
            previous,
            next,
            limit,
            ...defaultQuery
        } = defaultQueryShortcut
        const {
            sortField: sortField2,
            previous: previous2,
            next: next2,
            limit: limit2,
            ...currentQuery
        } = query
        return !_.isEqual(currentQuery, defaultQuery) 
        //eslint-disable-next-line
    }, [shortCut, query])

    

    return (
        <>
            <div className={`col-12 col-md-12 px-0 d-flex flex-column justify-content-between`} style={{ minHeight: '100%' }}>
                <div className={`d-flex flex-column m-0 p-0 mx-md-6 mx-lg-7`}>
                    <div className='container-fluid mt-2 mt-md-5 d-flex px-2 flex-column-reverse flex-md-row'>
                        <div className='d-flex flex-grow-1 align-items-center znk-scroll-horizontal mt-2 pr-0 pr-md-2 mt-md-0'>
                            {/* SHORTCUTS */}
                            {
                                shortCutList.map(
                                    ({key, label}, index) => {
                                        return (
                                            <span onClick={() => {
                                                //aplica filtro de status
                                                applyShortcut(key)
                                            }} className={`nav-title ${shortCut === key ? 'active' : ''} m-0 ${index === 0 ? 'mr-3' : 'mx-3'}`}>
                                                {label}
                                            </span>
                                        )
                                    }
                                )
                            }
                        </div>
                        <div className='d-md-flex d-none align-items-center justify-content-end justify-content-md-start flex-row-reverse flex-md-row'>
                            <div className={`hover-icon cursor-pointer text-info`} onClick={() => { history.push('/calendar') }}>
                                <IconProgram size={16} />
                            </div>
                            <span className={`ml-2`}>
                                <Button label='+ New Lesson' className='btn-table cursor-pointer' color='primary' onClick={() => {
                                    openNewLesson()
                                }} />
                            </span>
                        </div>
                    </div>
                    <div className={`d-flex flex-row m-0 p-0 justify-content-between`} style={{ minHeight: '68px' }}>
                    {
                        breakWidth === 'SM' ?
                            <ModalFilterMobile setQuery={setQuery} query={query} 
                            setUpcommingLesson={setUpcommingLesson}
                            studentsFilters={query?.studentIds || []}
                            setStudentsFilters={(studentIds) => {
                                setCurrentPage(1)
                                setQuery({ ...query, studentIds })
                            }}
                            educatorsFilters={query?.educatorIds || []}
                            setEducatorsFilters={(educatorIds) => {
                                setCurrentPage(1)
                                setQuery({ ...query, educatorIds })
                            }}
                            setLessonsState={() => { }}
                            originalLessons={[]}
                            >
                                <div className='hover-icon'><IconFilter size={20} className='text-disabled'/></div>
                            </ModalFilterMobile> :
                        <div className={`container-fluid mt-2 mb-4 mt-md-5 px-0 d-flex align-items-center znk-scroll-horizontal`}>
                            {/* missed,incomplete,noshow,finished,attended,upcoming,pending,inprogress */}
                            <StateFilter
                                statusFilters={query.filterStatus}
                                setStatusFilters={(filterStatus = []) => {
                                    // setUpcommingLesson([])
                                    setCurrentPage(1)
                                    setQuery({ ...query, filterStatus })
                                }}
                            />

                            <StudentFilter
                                setUpcommingLesson={setUpcommingLesson}
                                roles={['STUDENT']}
                                label={'Students'}
                                studentsFilters={query?.studentIds || []}
                                setStudentsFilters={(studentIds) => {
                                    // setUpcommingLesson([])
                                    setCurrentPage(1)
                                    setQuery({ ...query, studentIds })
                                }}
                                setLessonsState={() => { }}
                                originalLessons={[]}
                            />

                            <StudentFilter
                                roles={['EDUCATOR']}
                                label={'Educators'}
                                studentsFilters={query?.educatorIds || []}
                                setStudentsFilters={(educatorIds) => {
                                    setCurrentPage(1)
                                    setQuery({ ...query, educatorIds })
                                }}
                                setLessonsState={() => { }}
                                originalLessons={[]}
                            />

                            <FilterDate
                                setFilters={(newDates = []) => {
                                    const [start, end] = newDates
                                    setCurrentPage(1)
                                    setQuery({ ...query, startDate: moment(start).toISOString(), endDate: moment(end).toISOString() })
                                }}
                                filters={[query?.startDate, query?.endDate]}
                            />

                            <FilterService
                                setFilters={(serviceIds) => {
                                    setCurrentPage(1)
                                    setQuery({ ...query, serviceIds })
                                }}
                                filters={query?.serviceIds || []}
                            />

                            <TypeLessonFilter
                                setFilters={(typeLessons) => {
                                    setCurrentPage(1)
                                    setQuery({ ...query, typeLessons })
                                }}
                                filters={query?.typeLessons || []}
                            />
                            {
                                canClearFilters && (
                                    <Badge onClick={() => {
                                        applyShortcut(shortCut)
                                    }} className='mx-1 filters cursor-pointer appears-animation' pill color='option'>
                                        <label className='m-0 p-0 cursor-pointer'>
                                            {'Clear filters'}
                                            <IconSignPlus className='ml-2' size={15} />
                                        </label>
                                    </Badge>
                                )
                            }


                        </div>
                        }
                        <InputGroup className={`znk-card w-25 flex-nowrap`} title={'Search'} >
                            <input
                                className={"znk-input noRight trans form-control"}
                                placeholder={'Search'}
                                ref={searchInput}
                                onKeyUp={({ key }) => {
                                    if (key === 'Enter') {
                                        // setUpcommingLesson([])
                                        setCurrentPage(1)
                                        setQuery({ ...query, search: searchInput?.current?.value })
                                    }
                                }}
                            />
                            <InputGroupAddon addonType='prepend' className='icon'>
                                <InputGroupText type='text' className='znk-no-border'>
                                    <span className='addon'><IconSearch size={18} className='text-gray' /></span>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className={`m-0 py-0 px-2`}>
                        <TabContent activeTab={loading ? 'loading' : 'table'}>
                            <TabPane tabId={`loading`}>
                                <div className={`p-0 m-2`}>
                                    <Skeleton count={10} height={43} className={`mb-2`} style={{ borderRadius: '12px' }} />
                                </div>
                            </TabPane>
                            <TabPane tabId={`table`}>
                                <TableTask
                                    canCreated={false}
                                    onClickCreate={() => { }}
                                    onClick={(lesson) => {
                                        if (orientation?.id === lesson.service.id) {
                                            dispatch({
                                                type: 'SET_DATA_MODAL_FORM_LIST',
                                                payload: `plan.orientationLessonModal`,
                                                data: {
                                                    isOpen: true,
                                                    dataLesson: lesson
                                                }
                                            }
                                            )
                                        } else {
                                            openInfoLesson(lesson)
                                        }
                                    }}
                                    loading={false}
                                    className={`my-2 cursor-pointer`}
                                    config={config}
                                    rows={(lessons || []).map((item) => {
                                        return generateRow(item, upcommingLessons)
                                    })}
                                />
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
                <Pagination
                    className={breakWidth === 'SM' ? 'px-0' : `px-7`}
                    bottomFix
                    pageSize={query?.limit || 10}
                    changeSize={(limit) => {
                        setQuery({ ...query, limit })
                    }}
                    clickNext={() => {
                        if (hasNext && !loading) {
                            setCurrentPage(currentPage + 1)
                            setQuery({ ...query, next: next, previous: null })
                        }
                    }}
                    clickPrevious={() => {
                        if (hasPrevious && !loading && currentPage > 1) {
                            setCurrentPage(currentPage - 1)
                            setQuery({ ...query, previous: previous, next: null })
                        }
                    }}
                    loading={loading}
                    hasPrevious={hasPrevious}
                    hasNext={hasNext}
                    activePage={currentPage}
                    pageCount={Math.ceil((totalDocs || 0) / (query?.limit || 1))}
                />
            </div>
            <InfoLesson refetchCalendarQuery={() => {
                refetchCalendarQuery()
            }} />
            <OpenLessonModalFromUrl />
            <DuplicateLesson refetchPendingLessons={() => {
                refetchCalendarQuery()
            }} />
            <MakeUpLesson />
            <NewLesson refetchPendingLessons={() => {
                refetchCalendarQuery()
            }} />
            <EditPayment refetchPendingLessons={() => {
                refetchCalendarQuery()
            }} />
            <SetPendingLesson refetchPendingLessons={() => {
                refetchCalendarQuery()
            }} />
            <RescheduledLesson />
            <PenalizeLesson />
            <ChangeTimezone />
            <EditLesson refetchCalendar={() => {
                refetchCalendarQuery()
            }} />
            <WspAlert />
            <SendEmailReminder />
            {/* <InfoStudent refetch={() => { setRefetchIn(true) }} /> */}
            {/* <InfoEducator countries={countries} refetch={() => { setRefetchIn(true) }} /> */}
            <ScheduledConflict />
            <ConfirmDropRescheduled />
            <OrientationModal refetch={refetchCalendarQuery} />
            <ResumeOrientation />
            <ConfirmSetBraincertToLesson />
            <CancelLesson
                setLessonsSelected={() => { }}
                lessonsSelected={[]}
                refetch={() => {
                    refetchCalendarQuery()
                }}
            />
        </>
    )
}

export default LessonsList