import React, {useState, useEffect} from 'react'
import {Button} from '@Knowledge-OTP/znk-ui-components'
import {useMutation, gql} from '@apollo/client'

const AdjustLessons = (props) => {
    const {studentIds, changeDate, timezone, onBack, toggle, activeTab, appendsHours } = props


    //indice del usuario en proceso
    const [indexInProcess, setIndexInProcess] = useState(-1)
    const [finish, setFinish] = useState(false)
    //resultados de la mutación
    const [results, setResults] = useState({})
    useEffect(() => {
        if(activeTab === 'adjust-lessons') {
            setIndexInProcess(-1)
            setFinish(false)
            setResults({})
        }
        // eslint-disable-next-line
    }, [activeTab])

    const [adjustLessonsToDST, {loading}] = useMutation(
        gql`
            mutation update(
                $userId: String!,
                $changeDate: String!,
                $timezone: String!
                $appendsHours: Float!
            ) {
                adjustLessonsToDST(
                    userId: $userId,
                    changeDate: $changeDate,
                    timezone: $timezone,
                    appendsHours: $appendsHours
                ) 
            }
        `,
        {}
    )
    useEffect(() => {
        if(indexInProcess >= 0) {
            adjustLessonsToDST({
                variables: {
                    userId: studentIds[indexInProcess].id,
                    changeDate,
                    timezone,
                    appendsHours: appendsHours
                }
            }).then(
                (resp) => {
                    if (resp === true) {
                        setResults({
                            ...results,
                            [studentIds[indexInProcess].id]: {
                                type: 'success'
                            }
                        });
                        if (indexInProcess < studentIds.length - 1) {
                            setIndexInProcess(indexInProcess + 1);
                        } else {
                            setFinish(true);
                        }
                    } else {
                        setResults({
                            ...results,
                            [studentIds[indexInProcess].id]: {
                                type: 'error',
                                error: 'error'
                            }
                        });
                        if (indexInProcess < studentIds.length - 1) {
                            setIndexInProcess(indexInProcess + 1);
                        } else {
                            setFinish(true);
                        }
                    }
                }, 
                (result) => {
                    setResults({
                        ...results,
                        [studentIds[indexInProcess].id]: {
                            type: 'error',
                            error: result
                        }
                    })
                    if(indexInProcess < studentIds.length-1) setIndexInProcess(indexInProcess+1)
                    else {setFinish(true)}
                }
            )
        }
        // eslint-disable-next-line
    }, [indexInProcess])
    return (
        <div className={`m-0 p-0 d-flex flex-column`}>
            {
                studentIds.map(({id, firstName, lastName}, index) => {
                    return (
                        <div className={`d-flex flex-row justify-content-between m-0 p-0`}>
                            <span>{`${firstName} ${lastName}`}</span>
                            <span>
                                {
                                    results[id] ?
                                        results[id].type :
                                        loading && index === indexInProcess ? 'Cargando' : `Pendiente`
                                }
                            </span>
                        </div>
                    )
                })
            }
            <div className={'d-flex flex-row justify-content-end mt-2'}>
                {
                    !finish && (
                        <Button
                            className={'w-auto mr-1'}
                            color={'secondary'}
                            label={'Volver'}
                            onClick={onBack}
                            size={'small'}
                        />
                    )
                }
                <Button
                    activity={loading}
                    className={'w-auto'}
                    color={'primary'}
                    label={`${!finish ? 'Enviar' : 'Cerrar'}`}
                    size={'small'}
                    disabled={studentIds.length === 0}
                    onClick={() => {
                        if(finish) {
                            toggle()
                            return
                        }
                        if(studentIds.length > 0) setIndexInProcess(0)
                    }}
                />
            </div>
        </div>    
    )
}

export default AdjustLessons