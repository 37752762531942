import { Activity, IconArrowLeft, IconBank, IconCountry, IconEdit, IconErase, IconRight, IconUser, ModalLayout } from '@Knowledge-OTP/znk-ui-components'
import React, { Fragment, useMemo, useState, useEffect, useRef } from 'react'
import useTranslation from '../../../../i18n/useTranslation'
import completeUniversityInfo from '../../../../Model/Universities/ExternalUniversity/external'
import ShowUniversityComponent from './Components/ShowUniversity'
import useSetLogo from '../../../../Model/Universities/Edit/setLogo'
import moment from 'moment'
import { gql, useLazyQuery } from '@apollo/client'
import { pick } from 'lodash'
import getBase64 from '../../../../utils/getBase64'
import ViewUniversityWebsite from './Components/ViewUniversityWebsite'

const infoToObtain = [
    'name',
    'short_description',
    'city',
    'state_abbr',
    'type_category',
    'undergraduate_size',
    'acceptance_rate',
    'four_year_graduation_rate',
    'rankings_best_colleges',
    'sat_average',
    'act_cumulative_midpoint',
    'type_year',
    'avg_cost_of_attendance',
    'logo_image',
    'website',
]

const UniversityView = (props) => {
    const {toggle, state, dispatchModal} = props
    const {isOpen, isOwn, id, unitId} = state
    const [collegeData, setCollegeData] = useState(null)
    const [logo, setLogo] = useState('')
    const {translate} = useTranslation()
    const [selectedImage, setSelectedImage] = useState(null)
    const inputRef = useRef(null)
    const [getUniversity, { data, refetch }] = useLazyQuery(
        gql`query($universityId: ID!) {
            getUniversity(universityId: $universityId) {
                id
                name
                shortDescription
                collegeUnitId
                ranking
                stateAbbr
                city
                typeCategory
                typeYear
                undergraduateSize
                fourYearGraduationRate
                acceptanceRate
                satAverage
                actCumulativeMidpoint
                rankingsBestValue
                rankingsBestTotal
                avgCostOfAttendance 
                logo
            }
        }`
    )
    const [getLogo, {data: dataLogo}] = useLazyQuery(
        gql`query($universityId: String!) {
            getLogo(universityId: $universityId)
        }`
    )
    const { submit: submitLogo, loading: loadingLogo } = useSetLogo((result) => {
        getLogo({variables: {universityId: id}})
    }, console.log)  
    useMemo(() => {
        async function getDataExtrernal() {
            const collegeId = []
            collegeId.push([unitId])
            const info = await completeUniversityInfo(collegeId, infoToObtain)
            info.id = unitId
            // info.status = college?.status
            setCollegeData(info)
        }
        if (!isOwn) {
            getDataExtrernal()
            if (id) {
                getLogo({variables: {universityId: id}})
            }
        } else {
            getUniversity({variables: {universityId: id}})
        }
        // eslint-disable-next-line
    }, [id])
    useMemo(() => {
        if (data) {
            const dataTemp = pick(data.getUniversity, ['name', 'shortDescription', 'collegeUnitId', 'ranking', 'avgCostOfAttendance', 'stateAbbr', 'city', 'typeCategory', 'typeYear', 'undergraduateSize', 'fourYearGraduationRate', 'acceptanceRate', 'satAverage', 'actCumulativeMidpoint', 'rankingsBestValue', 'rankingsBestTotal'])
            dataTemp.logoImage = data.getUniversity.logo
            setCollegeData(dataTemp)
        }
        // eslint-disable-next-line
    }, [data])
    useEffect(() => {
        if (!dataLogo) return
        setLogo(dataLogo.getLogo)
        // eslint-disable-next-line
    }, dataLogo)
    const onSelectFile = (e) => {
        setSelectedImage(e.target.files[0])
        getBase64(e.target.files[0]).then(
            data => {
                submitLogo({universityId: id,  logo: data })
            }
        )
    }
    return (
        <ModalLayout
            className='modal-colored modal-sidebar modal-desktop-half'
            isOpen={isOpen}
            toggle={() => {
                //setActiveTab('summary')
                toggle()
            }}
            topHead={
                <div
                    className='d-flex flex-row align-items-start btn-link'
                    onClick={toggle}
                >
                    <div>
                    <IconArrowLeft size={15} color='#8c90ff' />
                    </div>
                    <div>
                    <span
                        className='ml-1'
                        style={{
                        color: '#8c90ff',
                        }}
                    >
                        {translate('counseling.back')}
                    </span>
                    </div>
                </div>
            }
            underHead={
                <div className='d-flex flex-column mt-3' style={{color: 'black'}}>
                    <div>
                        {   !loadingLogo
                                ?
                                    logo !== '' && <img src={!selectedImage ? logo : URL.createObjectURL(selectedImage)} alt='' style={{width: '24%'}} onClick={() => {inputRef.current.click()}} />
                                :
                                    <Activity  />
                        }
                    </div>
                    <div className='mt-4 h3'>{collegeData?.name}</div>
                    <div className='general-university-information'>
                        <div>
                            <input type="file" accept="image/*" hidden ref={inputRef} onChange={onSelectFile} />
                            <span className='h45 university-position mb-3'>
                            {collegeData?.rankingsBestValue && collegeData?.rankingsBestValue > 0
                                ? '#' +
                                collegeData.rankingsBestValue +
                                ' Best Colleges in America'
                                : ''}
                            </span>
                        </div>
                        <div className='primary-information mt-3 mb-2'>
                            <div className='d-flex flex-row'>
                            <span className='h45 w-25 text-secondary'>
                                <IconBank className='text-secondary mr-1' size={14} />{' '}
                                {collegeData?.typeCategory}
                            </span>
                            <span className='h45 w-75 text-secondary'>
                                <IconCountry className='text-secondary mr-1' size={14} />{' '}
                                {collegeData?.city}, {collegeData?.stateAbbr}
                            </span>
                            </div>
                            <div className='d-flex flex-row mt-2'>
                            <span className='h45 text-secondary'>
                                <IconUser className='text-secondary' size={14} /> ~
                                {(collegeData?.undergraduateSize / 1000).toFixed(1)} K{' '}
                                {translate('counseling.undergraduates')}
                            </span>
                            </div>
                            <ViewUniversityWebsite styles={'ml-0'} college={collegeData}/>
                        </div>
                        <span className='text-secondary line-height-base custom-scroll' style={{ color: '#49596F', height: '150px', 'scrollbar-color': '#a3aebe #F8F9FA' }}>
                            {collegeData?.shortDescription
                            ? collegeData?.shortDescription
                            : "There's no description"}
                        </span>
                    </div>
                </div>
            }
            useRightSection={false}
            disabledScrollAnimation={false}
        >
            <Fragment>
                <ShowUniversityComponent university={collegeData} />
                <div className='mt-3 pt-3'>
                    <div className='d-flex flex-row justify-content-between align-items-center mt-3'>
                        <div className='d-flex flex-column'>
                            <div className='d-flex flex-row'>
                            <h5 className='font-weight-bold h14 text-gray'>Created</h5><h5 className='ml-2 font-weight-light h14 text-gray'>{collegeData ? 'at ' + moment(collegeData?.createdAt).format('MMM DD, YYYY hh:mm a') : ''}</h5>
                            </div>
                            <div className='d-flex flex-row'>
                            <h4 className='font-weight-bold h14 text-gray'>Updated</h4><h4 className='ml-2 font-weight-light h14 text-gray'>{collegeData ? 'at ' + moment(collegeData?.updateAt).format('MMM DD, YYYY hh:mm a') : ''}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isOwn && <Fragment>
                        <div className='borderTop mt-4' />
                        <div className='d-flex flex-row justify-content-between mt-3 cursor' onClick={()=> {dispatchModal({type: 'editInside', id: id, refetch: refetch})}}>
                            <div className='d-flex flex-row align-items-center'>
                                <IconEdit size={16} color='#778699' className='mr-2' />
                                <span className='text-primary'>Edit University</span>
                            </div>
                            <IconRight size={16} color='#778699' />
                        </div>
                        <div className='borderTop mt-4' />
                        <div className='d-flex flex-row justify-content-between mt-3 cursor' onClick={()=> {dispatchModal({type: 'deleteInside', id: id})}}>
                            <div className='d-flex flex-row align-items-center'>
                                <IconErase size={16} color='#FF3E1D' className='mr-2' />
                                <span className='text-primary'>Remove University</span>
                            </div>
                            <IconRight size={16} color='#778699' />
                        </div>
                        <div className='borderTop mt-4' />
                    </Fragment>
                }
            </Fragment>
        </ModalLayout>
    )
}

export default UniversityView